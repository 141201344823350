.tab .nav-tabs {
    border-bottom: 0 none;
    margin-top: 20px;
    margin-bottom: 1.5px !important;
}

.tab .nav-tabs li button {
    font-size: 14px;
    color: #fff;
    margin-right: 0;
    padding: 10px 45px;
    border-radius: 0;
    overflow: hidden;
    background: #DAA520;
    text-transform: uppercase;
    box-shadow: -1px -6px 10px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;

}

.tab .nav-tabs li:first-child button {
    border-top-left-radius: 8px;
}

.tab .nav-tabs li:last-child button {
    border-top-right-radius: 8px;
}

.tab .nav-tabs li button:hover, .tab .nav-tabs li.active button:hover, .tab .nav-tabs li.active button {
    background: #fff;
    color: #000;
    margin-top: -4px;
    padding: 10px 45px;
    border: 1px solid #fff;
    text-decoration: none;
}

.tab .tab-content {
    color: #000;
    padding: 25px 20px;
    background: #fff;
    margin-top: 0px;
    border-radius: 0 0 8px 8px;
    box-shadow: 8px 12px 25px 2px rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 650px) {
    .tab .nav-tabs li {
        width:100%;
        margin: 5px;
        text-align: center;
    }

    .tab .nav-tabs li button {
        margin: 0;
        padding: 10px 45px;
        box-shadow: -2px -1px 25px 2px rgba(0, 0, 0, 0.4);
        width:100%;
    }

    .tab .nav-tabs li:first-child button, .tab .nav-tabs li:last-child button {
        border-radius: 0;
    }

    .tab .nav-tabs li button:hover, .tab .nav-tabs li.active button:hover, .tab .nav-tabs li.active button {
        margin: 0;
    }
}