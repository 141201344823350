.admin-layout-container .navbar {
  padding-right: 20px;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  min-height: 53px;
  margin-bottom: 20px;
}
.navbar a {
  vertical-align: middle;
}

.navbar a.dropdown-item {
  color: #66615b;
}
.navbar.bg-white .input-group .form-control,
.navbar.bg-white .input-group.no-border .form-control {
  color: #66615b;
}
.navbar.bg-white .input-group .form-control::-moz-placeholder,
.navbar.bg-white .input-group.no-border .form-control::-moz-placeholder {
  color: #66615b;
}
.navbar.bg-white .input-group .form-control:-ms-input-placeholder,
.navbar.bg-white .input-group.no-border .form-control:-ms-input-placeholder {
  color: #66615b;
}
.navbar.bg-white .input-group .form-control::-webkit-input-placeholder,
.navbar.bg-white .input-group.no-border .form-control::-webkit-input-placeholder {
  color: #66615b;
}
.navbar.bg-white .input-group-prepend .input-group-text i,
.navbar.bg-white .input-group-append .input-group-text i {
  color: #66615b;
  opacity: 0.5;
}
.navbar .form-group,
.navbar .input-group {
  margin: 0;
  margin-left: -3px;
  margin-right: 5px;
}
.navbar .form-group .form-group-addon,
.navbar .form-group .input-group-prepend .input-group-text,
.navbar .form-group .input-group-append .input-group-text,
.navbar .input-group .form-group-addon,
.navbar .input-group .input-group-prepend .input-group-text,
.navbar .input-group .input-group-append .input-group-text {
  color: #66615b;
}
.navbar .form-group .form-group-addon i,
.navbar .form-group .input-group-prepend .input-group-text i,
.navbar .form-group .input-group-append .input-group-text i,
.navbar .input-group .form-group-addon i,
.navbar .input-group .input-group-prepend .input-group-text i,
.navbar .input-group .input-group-append .input-group-text i {
  opacity: 1;
}
.navbar .form-group.no-border .form-control,
.navbar .input-group.no-border .form-control {
  color: #66615b;
}
.navbar .form-group.no-border .form-control::-moz-placeholder,
.navbar .input-group.no-border .form-control::-moz-placeholder {
  color: #66615b;
}
.navbar .form-group.no-border .form-control:-ms-input-placeholder,
.navbar .input-group.no-border .form-control:-ms-input-placeholder {
  color: #66615b;
}
.navbar .form-group.no-border .form-control::-webkit-input-placeholder,
.navbar .input-group.no-border .form-control::-webkit-input-placeholder {
  color: #66615b;
}
.navbar p {
  display: inline-block;
  margin: 0;
  line-height: 1.8em;
  font-size: 1em;
  font-weight: 400;
}
.navbar.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}
.navbar.navbar-relative {
  position: relative;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}
.documentation .navbar.fixed-top {
  left: 0;
  width: initial;
}
.navbar .navbar-wrapper {
  padding: 10px 16px;
  display: inline-flex;
  align-items: center;
}
.navbar .navbar-wrapper .navbar-minimize {
  padding-right: 10px;
}
.navbar .navbar-wrapper .navbar-minimize .btn {
  margin: 0;
}
.navbar .navbar-wrapper .navbar-toggle .navbar-toggler {
  padding-left: 0;
}
.navbar .navbar-wrapper .navbar-toggle:hover .navbar-toggler-bar.bar2 {
  width: 22px;
}
.navbar .navbar-nav.navbar-logo {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 49px;
  top: -4px;
}
.navbar .navbar-nav .nav-link.btn {
  padding: 11px 22px;
}
.navbar .navbar-nav .nav-link.btn.btn-lg {
  padding: 15px 48px;
}
.navbar .navbar-nav .nav-link.btn.btn-sm {
  padding: 5px 15px;
}
.navbar .navbar-nav .nav-link {
  text-transform: uppercase;
  font-size: 0.7142em;
  padding: 0.5rem 0.7rem;
  line-height: 1.625rem;
  margin-right: 3px;
}
.navbar .navbar-nav .nav-link i.fa + p,
.navbar .navbar-nav .nav-link i.nc-icon + p {
  margin-left: 3px;
}
.navbar .navbar-nav .nav-link i.fa,
.navbar .navbar-nav .nav-link i.nc-icon {
  font-size: 18px;
  position: relative;
  top: 3px;
  text-align: center;
  width: 21px;
}
.navbar .navbar-nav .nav-link i.nc-icon {
  top: 4px;
  font-size: 16px;
}
.navbar .navbar-nav .nav-link.profile-photo .profile-photo-small {
  width: 27px;
  height: 27px;
}
.navbar .navbar-nav .nav-link.disabled {
  opacity: 0.5;
  color: #ffffff;
}
.navbar .navbar-nav .nav-item.active .nav-link:not(.btn),
.navbar .navbar-nav .nav-item .nav-link:not(.btn):focus,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):hover,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):active {
  border-radius: 3px;
  color: #66615b;
}
.navbar .logo-container {
  width: 27px;
  height: 27px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid transparent;
}
.navbar .navbar-brand {
  text-transform: capitalize;
  font-size: 20px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.625rem;
}

.navbar .navbar-toggler .navbar-toggler-bar.navbar-kebab {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 0 auto;
}
.navbar .button-dropdown .navbar-toggler-bar:nth-child(2) {
  width: 17px;
}
.navbar.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
}
.navbar.navbar-transparent a:not(.dropdown-item):not(.btn) {
  color: #66615b;
}
.navbar.navbar-transparent a:not(.dropdown-item):not(.btn).disabled {
  opacity: 0.5;
  color: #66615b;
}
.navbar.navbar-transparent .button-bar {
  background: #66615b;
}
.navbar.navbar-transparent .nav-item .nav-link:not(.btn) {
  color: #66615b;
}
.navbar.navbar-transparent .nav-item.active .nav-link:not(.btn),
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):focus,
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):hover,
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):focus:hover,
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):active {
  color: #51cbce;
}
.navbar.bg-white a:not(.dropdown-item):not(.btn) {
  color: #66615b;
}
.navbar.bg-white a:not(.dropdown-item):not(.btn).disabled {
  opacity: 0.5;
  color: #66615b;
}
.navbar.bg-white .button-bar {
  background: #66615b;
}
.navbar.bg-white .nav-item.active .nav-link:not(.btn),
.navbar.bg-white .nav-item .nav-link:not(.btn):focus,
.navbar.bg-white .nav-item .nav-link:not(.btn):hover,
.navbar.bg-white .nav-item .nav-link:not(.btn):active {
  color: #51bcda;
}
.navbar.bg-white .logo-container {
  border: 1px solid #66615b;
}
.navbar .navbar-collapse .nav-item a {
  font-size: 14px;
}

.bg-default {
  background-color: #66615b !important;
}

.bg-primary {
  background-color: #51cbce !important;
}

.bg-info {
  background-color: #51bcda !important;
}

.bg-success {
  background-color: #6bd098 !important;
}

.bg-danger {
  background-color: #ef8157 !important;
}

.bg-warning {
  background-color: #fbc658 !important;
}

.bg-white {
  background-color: #ffffff !important;
}
