.StarPickerButton {
  transition: color 0.2s;
  color: #212529;
}

.StarPickerButton.selected {
  color: #212529;
}

.StarPickerButton.addSelection {
  color: rgba(33, 37, 41, 0.5);
}

.DefaultStarComponent.removeSelection {
  color: rgba(33, 37, 41, 0.5);
}
