body {
  font-size: 1rem;
  max-width: 100vw;
  overflow-x: hidden;
  color: #212529;
}
.error {
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}

.dropdown-item.active {
  background: none;
}

a {
  color: #c79006;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #c79006;
  text-decoration: none !important;
}

.icon-with-text {
  color: #c79006;
  text-decoration: none;
  background-color: transparent;
}

.icon-with-text:hover {
  color: #c79006;
  text-decoration: none !important;
}

a.btn {
  font-size: 1rem;
  font-weight: 500;
  padding: 6px 12px;
  letter-spacing: 0.4px;
  /* border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px; */
}

a.btn-gold,
.btn-gold {
  background-color: #daa520;
  border-color: #daa520;
  color: #fff;
}

/* START Styling av guld-knapp*/
.btn-gold {
  color: #ffffff;
  background-color: #daa520;
  border-color: #daa520;
}
.btn-gold:hover,
.btn-gold:focus,
.btn-gold:active,
.btn-gold.active,
.open .dropdown-toggle.btn-gold {
  color: #ffffff;
  background-color: #c79006;
  border-color: #daa520;
}
.btn-gold:active,
.btn-gold.active,
.open .dropdown-toggle.btn-gold {
  background-image: none;
}
.btn-gold.disabled,
.btn-gold[disabled],
fieldset[disabled] .btn-gold,
.btn-gold.disabled:hover,
.btn-gold[disabled]:hover,
fieldset[disabled] .btn-gold:hover,
.btn-gold.disabled:focus,
.btn-gold[disabled]:focus,
fieldset[disabled] .btn-gold:focus,
.btn-gold.disabled:active,
.btn-gold[disabled]:active,
fieldset[disabled] .btn-gold:active,
.btn-gold.disabled.active,
.btn-gold[disabled].active,
fieldset[disabled] .btn-gold.active {
  background-color: #daa520;
  border-color: #daa520;
}
.btn-gold .badge {
  color: #daa520;
  background-color: #ffffff;
}
/* SLUT Styling av guld-knapp*/

.btn-gold.disabled,
.btn-gold[disabled],
fieldset[disabled] .btn-gold,
.btn-gold.disabled:hover,
.btn-gold[disabled]:hover,
fieldset[disabled] .btn-gold:hover,
.btn-gold.disabled:focus,
.btn-gold[disabled]:focus,
fieldset[disabled] .btn-gold:focus,
.btn-gold.disabled:active,
.btn-gold[disabled]:active,
fieldset[disabled] .btn-gold:active,
.btn-gold.disabled.active,
.btn-gold[disabled].active,
fieldset[disabled] .btn-gold.active {
  background-color: #daa52060;
  border-color: #daa52060;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
}

.navbar-light:hover .navbar-nav:hover .nav-link:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}
.navbar-light .navbar-toggler {border-color: rgba(0, 0, 0, 0);}

nav,
p,
span,
li,
label {
  font-family: 'Source Sans Pro', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h7 {
  font-family: 'Playfair Display', serif;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

/* START Register knappar fb och google */
.btn-google {
  color: white;
  background-color: #ea4335;
}
.btn-google {
  border-radius: 0.3rem;
  letter-spacing: 0.1rem;
  font-weight: 400;
  padding: 0.5rem;
  transition: all 0.2s;
}
.btn-facebook {
  color: white;
  background-color: #3b5998;
}
.btn-facebook {
  border-radius: 0.3rem;
  letter-spacing: 0.1rem;
  font-weight: 400;
  padding: 0.5rem;
  transition: all 0.2s;
}
/* SLUT Register knappar fb och google */
.register-bg-image,
.register-bg-image1,
.register-bg-image2,
.register-bg-image3,
.register-bg-image4 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 550px;
}

.multi_step_form #msform fieldset .form-control:hover,
.multi_step_form #msform fieldset .form-control:focus,
.multi_step_form #msform fieldset .product_select:hover,
.multi_step_form #msform fieldset .product_select:focus {
  border-color: #daa520;
}

.login {
  min-height: 100vh;
}

.pos-relative {
  position: relative;
}

/* Search Trips */
.booking-btn{
  white-space: nowrap;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 768px) {
  .booking-btn {
    position: relative;
  }

  .scrolled {
    /* Styles applied when scrolling */
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    padding: 12px 24px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}
.display_operator_name {
  padding-top: 30px;
  text-transform: uppercase;
}

.display_operator_photo-header {
  padding: 20px;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.display_operator_photo {
  padding: 20px;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.display_operator-trips {
  margin-top: 20px;
  padding: 20px;
}
.display_operator-maps {
}

.searchpage_input input,
.searchpage_input select,
.searchpage_input .radio-label::before,
.searchpage_input .checkbox-label::before {
  /* shared style for input elements */
  font-family: 'Open Sans', sans-serif;
  border-radius: 0;
  background-color: #ffffff;
  border: 2px solid #e6e6e6;
}

.searchpage_input input[type='search'],
.searchpage_input input[type='text'],
.searchpage_input select {
  width: 100%;
  padding: 0.8em;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-shadow: none;
}
.searchpage_input input[type='search']:focus,
.searchpage_input input[type='text']:focus,
.searchpage_input select:focus {
  outline: none;
  background-color: #ffffff;
  border-color: #daa520;
}
.searchpage_input input[type='search'],
.searchpage_input input[type='text'],
.searchpage_input select {
  /* custom style for the search element */
  border-color: transparent;
  background-color: #e6e6e6;
  /* prevent jump - ios devices */
  font-size: 1rem !important;
  color: #5f6771 !important;
}
.searchpage_input .cd-select {
  /* select element wrapper */
  position: relative;
}
.searchpage_input .cd-select::after {
  content: '';
  position: absolute;
  z-index: 1;
  right: 14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  width: 16px;
  height: 16px;
  /* background: url(img/cd-icon-arrow.svg) no-repeat center center; */
  pointer-events: none;
}

.searchpage_yoursearch {
  max-width: 1200px;
}

.fbkr-input.gray {
  border: 1px solid #ccc;
}
.fbkr-input {
  width: 100%;
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  background-color: #fff;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.fbkr-input,
.fbkr-react-alert .modal-footer,
.fbkr-react-alert .modal-header {
  border: none;
}

.fbkr-button {
  width: 100%;
  min-height: 44px;
  border: none;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.fbkr-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 44px;
  padding: 0 12px;
  font-size: 14px;
  background-color: #fff;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.style-guide select.arrow {
  background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyBmaWxsPScjNzc3Nzc3OycgaGVpZ2h0PScyNCcg…BsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: right;
}
.fbkr-select.arrow {
  padding: 0 25px 0 12px;
}
.fbkr-select.gray {
  border: 1px solid #ccc;
}
.glyphicon {
  font-family: glyphicons regular;
}
.glyphicon,
.social {
  position: relative;
  top: 1px;
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
}
.nice-select:hover {
  border-color: #dbdbdb;
}
.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #999;
}
.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}
.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled:after {
  border-color: #cccccc;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small:after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

*.hidden {
  display: none !important;
}

.initial-avatar {
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Colors */
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;

  /* Rounded border */
  border-radius: 50%;
  height: 60px;
  width: 60px;

  /* Shadow */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.tile {
  background-color: white;
  height: 5em;
  margin: 3px;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  max-width: 350px;
}

.tile-img {
  background-size: cover;
  background-position: center center;
  background-repeat: none;
  width: 4em;
  height: inherit;
  float: left;
}

.tile-info {
  padding: 5px;
}

.notification-icon {
  position: relative;
  margin-right: 1em;
  border-radius: 5px;
  i {
    margin: 0.5rem;
  }
  &:nth-of-type(1) {
    i {
      background: -webkit-linear-gradient(300deg, #acccea, #6495ed);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &:nth-of-type(2) i {
    background: -webkit-linear-gradient(300deg, #fff9ab, #00b8ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.num-count {
  position: absolute;
  user-select: none;
  cursor: default;
  font-size: 0.6rem;
  background: #e74c3c;
  width: 1.2rem;
  height: 1.2rem;
  color: #ecf0f1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  box-sizing: border-box;
  animation: pulse 0.75s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(234, 9, 9, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(234, 9, 9, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(234, 9, 9, 0);
  }
}

.image-gallery-image {
  min-height: 300px;
}

.marker-count {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: bold;
  color: #daa520;
}
.nav-item a {
  font-size: 18px !important;
}

.hideContent .dropdown-toggle::after {
  content: none !important;
}

.navbar {
  min-height: 90px;
}

.translatedBy {
  position: relative;
  top: -15px;
  color: gray;
  float: right;
}

.ck .ck-powered-by {
  display: none !important;
}

.ck .ck-content {
  max-height: 200px; /* Set maximum height */
  height: 200px; /* Set maximum height */
}

.react-datepicker-wrapper {
  width: 100%;
}
