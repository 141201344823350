.info-page .container-fluid.display_operator_image {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 550px;
}

.info-page .tothabottom {
  vertical-align: bottom;
}

.info-page .container {
  max-width: 1320px;
}

.info-page .display_operator-header {
  max-width: 700px;
  margin-top: 150px;
}

.info-page .display_operator-header span.h6 {
  text-shadow: 0px 0px 0px black, 0 0px 10px black, 0 0 0px black;
}

.info-page .display_images-header {
  margin-top: 60px;
}

.info-page .display_shortinfo-header {
  display: none;
}

.info-page .display_search-header {
  display: none;
}

.info-page .whitetext {
  color: #ffffff;
}

.info-page .display_operator_name {
  padding-top: 30px;
  text-transform: uppercase;
  text-shadow: 0px 0px 0px black, 0 0px 10px black, 0 0 0px black;
}

.info-page .display_operator_shortinfo {
  margin-top: 21px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  padding: 20px;
  background-color: #eeeeee;
}

.info-page .avatar {
  width: 124px;
  height: 124px;
  border: 1px solid #ffffff;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover;
}

.info-page .display_operator_photo-header {
  padding: 20px;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.info-page .display_operator_photo {
  padding: 20px 20px 0px 20px;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.info-page .display_operator-trips {
  margin-top: 20px;
  padding: 20px;
}

.info-page .fixabanan {
  line-height: 1.2;
  margin-bottom: -10px;
  margin-top: 0px;
}

.info-page .accordion-header del {
  position: absolute;
  top: 20px;
  right: 60px;
  text-decoration: none;
  color: #c79006;
  font-size: 1rem;
}

.info-page .accordion-header .btn-link {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  padding-left: 0px;
}

.info-page .accordion-header {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0px;
}

.info-page .accordion-item {
  border-radius: 0px;
  margin-bottom: 10px;
}

.info-page #wrapper {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px auto;
  /* width:420px; */
  width: 300px;
}

.info-page input[type='checkbox'] {
  display: none;
}

.info-page .checkbox:checked + .label {
  background: none;
  color: #ccc;
}

.info-page label:hover,
.info-page label:active,
.info-page input:hover + label,
.info-page input:active + label {
  color: #666;
}

.info-page .checkbox:checked ~ .social {
  opacity: 1;
  transform: scale(1) translateY(-90px);
}

.info-page .label {
  background: none;
  font-size: 18px;
  cursor: pointer;
  margin-top: 0px;
  padding: 5px 10px;
  border-radius: 10%;
  color: #fff;
  display: inline-block;
  text-shadow: 0 0 0 #000, 0 0 10px #000, 0 0 0 #000;
  font-weight: 800;
}

.info-page .social {
  transform-origin: 50% 0%;
  transform: scale(0) translateY(-190px);
  opacity: 0;
  transition: 0.5s;
}

.info-page .social ul {
  position: relative;
  left: 0;
  right: 0;
  margin: -5px auto 0;
  color: #fff;
  height: 46px;
  /* width:420px; */
  width: 280px;
  background: none;
  padding: 0;
  list-style: none;
}

.info-page .social ul li {
  font-size: 20px;
  cursor: pointer;
  width: 60px;
  margin: 0;
  padding: 12px 0;
  text-align: center;
  float: left;
  display: block;
  height: 46px;
}

.info-page .social ul li:hover {
  color: rgba(0, 0, 0, 0.5);
}

.info-page .social ul:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 100px;
  margin: 46px auto;
  height: 0;
  width: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #3b5998;
}

.info-page li[class*='twitter'] {
  background: #6cdfea;
  padding: 12px 0;
}

.info-page li[class*='facebook'] {
  background: #3b5998;
  padding: 12px 0;
}

.info-page li[class*='gplus'] {
  background: #e34429;
  padding: 12px 0;
}

.info-page li[class*='dropbox'] {
  background: #8dc5f2;
  padding: 12px 0;
}

.info-page li[class*='github'] {
  background: #9c7a5b;
  padding: 12px 0;
}

.info-page li[class*='instagram'] {
  background: #0e68ce;
  padding: 12px 0;
}

.info-page li[class*='youtube'] {
  background: #cc181e;
  padding: 12px 0;
}

.info-page .accordion-header .fa-minus {
  color: #c79006;
}

.info-page .accordion-item {
  border-radius: 0px;
  margin-bottom: 10px;
}

.info-page .accordion-header button {
  background-color: transparent;
}

.info-page .carousel-inner {
  max-height: 200px;
}

.info-page .carousel-inner .carousel-item.active,
.info-page .carousel-inner .carousel-item-next,
.info-page .carousel-inner .carousel-item-prev {
  display: flex;
}

.info-page .carousel-inner .carousel-item-end,
.info-page .carousel-inner .carousel-item-start {
  transform: translateX(0);
}

.info-page .display_operator_info {
  margin-top: 20px;
  padding-top: 20px;
  background-color: #ffffff;
  position: relative;
}

.info-page .display_operator_info del {
  /* position: relative; */
  right: 10px;
  text-decoration: none;
  color: #000000;
  font-size: 20px;
}

.info-page .packages-outer-container {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.info-page .review-block {
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 15px;
}
.info-page .review-block-name {
  font-size: 12px;
  margin-left: 75px;
  margin-top: -61px;
}
.info-page .review-block-date {
  font-size: 12px;
  margin-left: 75px;
}
.info-page .review-block-rate {
  font-size: 13px;

  margin-bottom: 5px;
}
.info-page .review-block-title {
  margin-bottom: 10px;
}
.info-page .review-block-description {
  font-size: 13px;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}



@media (min-width: 768px) {
  html {
    font-size: 16px;
  }

  .info-page .carousel-inner .carousel-item-end.active,
  .info-page .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }

  .info-page .carousel-inner .carousel-item-start.active,
  .info-page .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}

@media (max-width: 767px) {
  /* .info-page .display_operator_info .booking-btn {
    margin-top: 15px !important;
  } */
  .info-page .display_operator_info {
    display: block !important;
    padding-top: 0px !important;
  }
  .info-page .display_operator_info del {
    margin-top: 15px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .info-page .container-fluid.display_operator_image {
    min-height: 400px;
  }
  .info-page #wrapper {
    margin: 0px auto;
  }
  .info-page .display_operator-header {
    margin-top: 100px;
  }

  .info-page .carousel-inner .carousel-item > div {
    display: none;
  }
  .info-page .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}
