.StarPickerButton {
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: transparent;
  line-height: 100%;

  width: var(--star-size);
  font-size: var(--star-size);
}

.StarPickerButton.disabled {
  cursor: not-allowed;
}

.StarPickerButton.halfStars {
  overflow: hidden;
  direction: ltr;

  width: calc(var(--star-size) / 2);
}

.StarPickerButton.halfStars:nth-child(even) {
  direction: rtl;
}
