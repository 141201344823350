header {
  margin-top: 80px;
}
header .dropdown-menu {
  border: none;
}
header .drop-right-menu[data-bs-popper] {
  right: 0;
  left: auto;
}
.seprator {
  padding: 0px 10px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
}

@media only screen and (max-width: 990px) {
  header .dropdown-menu {
    border: none;
  }
  .seprator {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  header {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 500px) {
  header {
    margin-top: 20px;
  }
}
