.landing-page .landing-page__section-one .carousel {
  position: relative;
  left: 0;
}
.landing-page .landing-page__section-one .carousel h2,
.landing-page .landing-page__section-one .carousel p {
  color: white;
}
.landing-page .landing-page__section-one .carousel .carousel-item {
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
  height: calc(100vh - 80px);
}

@media (max-width: 768px) {
  .landing-page .landing-page__section-one .carousel .carousel-item {
    background-position: center;
  }
}

.landing-page .landing-page__section-one .lead {
  font-size: 1.25rem;
  line-height: 1.5;
}

.landing-page .landing-page__section-one .carousel-caption {
  bottom: 50vh;
}
.landing-page .landing-page__search {
  position: absolute;
  padding: 2rem;
  z-index: 1000;
  color: white;
  max-width: 960px;
  display: block;
  margin: auto;  
  bottom:  15%;
}
@media screen and (min-width: 576px) {
  .landing-page .landing-page__search {
    right: 10%;
    left: 10%;
    bottom: 15%;
  }
}

@media screen and  (min-width: 768px) {
  .landing-page .landing-page__search {
    right: 12%;
    left: 12%;
    bottom: 22%;
  }
}

@media screen and  (min-width: 992px) {
  .landing-page .landing-page__search {
    right: 5%;
    left: 5%;
    bottom: 33%;
  }
}

@media screen and  (min-width: 1200px) {
  .landing-page .landing-page__search {
    right: 10%;
    left: 10%;
    bottom: 39%;
  }
}



@media (max-width: 385px) {
  .landing-page .landing-page__section-one .carousel .carousel-item .display-4{
    font-size: 1.475rem;
  }
  .landing-page .landing-page__search {
    bottom: 10%;
  }
  .landing-page .landing-page__section-one .carousel-caption {
    bottom: 55vh;
  }
}
.landing-page .landing-page__search .landing-page__search__form {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px 40px;
  border-radius: 3px;
}
.landing-page .landing-page__search .landing-page__search__form .form-control {
  background-color: #ffffff;
  height: 45px;
  border-radius: 0px;
  border: 1px solid black;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
  color: #101113;
  padding: 0px;
}

.landing-page .landing-page__search .landing-page__search__form .form-control input:focus {
  box-shadow: none;
}

.landing-page .landing-page__search .landing-page__search__form .form-label {
  color: #ffffff;
}

.landing-page .landing-page__search .landing-page__search__form .fbkr-button {
  border-radius: 0px;
  width: 100%;
  min-height: 48px;
  border: none;
  box-shadow: none;
}

.landing-page .search-sec {
  position: absolute;
  /* padding: 2rem; */
  z-index: 1000;
  color: white;
  /* max-width: 960px; */
  display: block;
  /* margin: auto; */
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.71);
  height: 104px;
  padding: 32px;
}

.landing-page .impstats {
  color: #aaa;
}
.landing-page .impstats p,
.landing-page .impstats h3 {
  color: #aaa;
}
.landing-page .impstats h3 {
  font-size: 1.75rem;
}

.landing-page .h1,
.landing-page .h2,
.landing-page .h3,
.landing-page .h4,
.landing-page .h5,
.landing-page .h6,
.landing-page h1,
.landing-page h2,
.landing-page h3,
.landing-page h4,
.landing-page h5,
.landing-page h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.landing-page .landing-page__discover-stories {
  background-color: #eeeeee;
}

.search-page .search-page__search {
  width: 100%;
}

.search-page .form-group {
  padding: 0 10px;
}


@media screen and (max-width: 768px) and (min-width: 100px) {
  .search-page .form-group {
    padding:10px 0px;
  }
  .form-group {
    padding:5px 0px;
  }
}

@media screen and  (min-width: 768px) {
  .search-page .form-group {
    padding: 0 10px;
  }
 
}

.search-page .search-page__search .search-page__search__form {
  padding: 20px 40px;
  border-radius: 3px;
}

.search-page .search-page__search .search-page__search__form .form-label {
  color: black;
}

.search-page .search-page__search .search-page__search__form .form-control {
  height: 45px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
  padding: 0px;
}
.search-page .search-page__search .search-page__search__form .form-control input:focus {
  box-shadow: none;
}

.search-page .search-page__search .search-page__search__form .fbkr-button {
  border-radius: 0px;
  width: 100%;
  min-height: 48px;
  border: none;
  box-shadow: none;
}
