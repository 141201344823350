.scene-guide-update_wizard .upload-files input {
  display: none;
}

.scene-guide-update_wizard .upload-files .profile-pic + label,
.scene-guide-update_wizard .upload-files .businessMedia + label {
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  background-color: #ffffff;
}
.scene-guide-update_wizard .upload-files .profile-pic + label strong,
.scene-guide-update_wizard .upload-files .businessMedia + label strong {
  height: 100%;
  color: #ffffff;
  background-color: #daa520;
  border: 1px solid #daa520;
  padding: 0.625rem 1.25rem;
  float: right;
}
.scene-guide-update_wizard .upload-files .profile-pic + label svg,
.scene-guide-update_wizard .upload-files .businessMedia + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.scene-guide-update_wizard .upload-files .profile-pic + label span,
.scene-guide-update_wizard .upload-files .businessMedia + label span {
  width: 404px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
  color: #daa520;
}
