.multi_step_form {
  display: block;
  overflow: hidden;
}
.multi_step_form #msform {
  text-align: center;
  position: relative;
  min-height: 920px;
  max-width: 810px;
  margin: 0 auto;
  background: #eeeeee;
  z-index: 1;
  padding-top: 50px;
}
.multi_step_form #msform .tittle {
  text-align: center;
  padding-bottom: 55px;
}
.multi_step_form #msform .tittle h2 {
  font:
    500 24px/35px 'Roboto',
    sans-serif;
  color: #3f4553;
  padding-bottom: 5px;
}
.multi_step_form #msform .tittle p {
  font:
    400 16px/28px 'Roboto',
    sans-serif;
  color: #5f6771;
}
.multi_step_form #msform fieldset {
  border: 0;
  padding: 20px 105px 0;
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  margin-bottom: 30px;
}
@media screen and (max-width: 576px) {
  .multi_step_form #msform fieldset {
    padding: 20px 15px 0;
  }
}
.multi_step_form #msform fieldset:not(:first-of-type) {
  display: none;
}
.multi_step_form #msform fieldset h3 {
  font:
    500 18px/35px 'Roboto',
    sans-serif;
  color: #3f4553;
}
.multi_step_form #msform fieldset h6 {
  font:
    400 15px/28px 'Roboto',
    sans-serif;
  color: #5f6771;
  padding-bottom: 30px;
}
.multi_step_form #msform fieldset .intl-tel-input {
  display: block;
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
}
.multi_step_form #msform fieldset .intl-tel-input .flag-container .selected-flag {
  padding: 0 20px;
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  width: 65px;
}
.multi_step_form #msform fieldset .intl-tel-input .flag-container .selected-flag .iti-arrow {
  border: 0;
}
.multi_step_form #msform fieldset .intl-tel-input .flag-container .selected-flag .iti-arrow:after {
  content: '\f35f';
  position: absolute;
  top: 0;
  right: 0;
  font: normal normal normal 24px/7px Ionicons;
  color: #5f6771;
}
.multi_step_form #msform fieldset #phone {
  padding-left: 80px;
}
.multi_step_form #msform fieldset .form-group {
  padding: 0 10px;
}

@media screen and (max-width: 768px) and (min-width: 100px) {
  .multi_step_form #msform fieldset .form-group {
    padding: 10px 10px;
  }
  .multi_step_form #msform fieldset section {
    padding: 10px 10px;
  }
}

.multi_step_form #msform fieldset .fg_2,
.multi_step_form #msform fieldset .fg_3 {
  padding-top: 10px;
  display: block;
  overflow: hidden;
}
.multi_step_form #msform fieldset .fg_3 {
  padding-bottom: 70px;
}
.multi_step_form #msform fieldset .form-control {
  border-radius: 3px;
  border: 1px solid #d8e1e7;
  padding: 0 20px;
  height: auto;
  font:
    400 15px/48px 'Roboto',
    sans-serif;
  color: #5f6771;
  box-shadow: none;
  outline: none;
  width: 100%;
}

.multi_step_form #msform fieldset .product_select {
  border-radius: 3px;
  border: 1px solid #d8e1e7;
  height: auto;
  color: #5f6771;
  box-shadow: none;
  outline: none;
  width: 100%;

}

.multi_step_form #msform fieldset .product_select input,
.multi_step_form #msform fieldset .product_select input:focus {
  border: 0px;
  box-shadow: none;
}

.multi_step_form #msform fieldset .form-control.placeholder,
.multi_step_form #msform fieldset .product_select.placeholder {
  color: #5f6771;
}
.multi_step_form #msform fieldset .form-control:-moz-placeholder,
.multi_step_form #msform fieldset .product_select:-moz-placeholder {
  color: #5f6771;
}
.multi_step_form #msform fieldset .form-control::-moz-placeholder,
.multi_step_form #msform fieldset .product_select::-moz-placeholder {
  color: #5f6771;
}
.multi_step_form #msform fieldset .form-control::-webkit-input-placeholder,
.multi_step_form #msform fieldset .product_select::-webkit-input-placeholder {
  color: #5f6771;
}
.multi_step_form #msform fieldset .form-control:hover,
.multi_step_form #msform fieldset .form-control:focus,
.multi_step_form #msform fieldset .product_select:hover,
.multi_step_form #msform fieldset .product_select:focus {
  border-color: #daa520;
}
.multi_step_form #msform fieldset .form-control:focus.placeholder,
.multi_step_form #msform fieldset .product_select:focus.placeholder {
  color: transparent;
}
.multi_step_form #msform fieldset .form-control:focus:-moz-placeholder,
.multi_step_form #msform fieldset .product_select:focus:-moz-placeholder {
  color: transparent;
}
.multi_step_form #msform fieldset .form-control:focus::-moz-placeholder,
.multi_step_form #msform fieldset .product_select:focus::-moz-placeholder {
  color: transparent;
}
.multi_step_form #msform fieldset .form-control:focus::-webkit-input-placeholder,
.multi_step_form #msform fieldset .product_select:focus::-webkit-input-placeholder {
  color: transparent;
}

.multi_step_form #msform fieldset .product_select .list {
  width: 100%;
}

.multi_step_form #msform fieldset .done_text .don_icon {
  height: 36px;
  width: 36px;
  line-height: 36px;
  font-size: 22px;
  margin-bottom: 10px;
  background: #daa520;
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
}
.multi_step_form #msform fieldset .done_text h6 {
  line-height: 23px;
}
.multi_step_form #msform fieldset .code_group {
  margin-bottom: 60px;
}
.multi_step_form #msform fieldset .code_group .form-control {
  border: 0;
  border-bottom: 1px solid #a1a7ac;
  border-radius: 0;
  display: inline-block;
  width: 30px;
  font-size: 30px;
  color: #5f6771;
  padding: 0;
  margin-right: 7px;
  text-align: center;
  line-height: 1;
}
.multi_step_form #msform fieldset .passport {
  margin-top: -10px;
  padding-bottom: 30px;
  position: relative;
}
.multi_step_form #msform fieldset .passport .don_icon {
  height: 36px;
  width: 36px;
  line-height: 36px;
  font-size: 22px;
  position: absolute;
  top: 4px;
  right: 0;
  background: #daa520;
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
}
.multi_step_form #msform fieldset .passport h4 {
  font:
    500 15px/23px 'Roboto',
    sans-serif;
  color: #5f6771;
  padding: 0;
}
.multi_step_form #msform fieldset .input-group {
  padding-bottom: 40px;
}
.multi_step_form #msform fieldset .input-group .custom-file {
  width: 100%;
  height: auto;
}
.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label {
  width: 168px;
  border-radius: 5px;
  cursor: pointer;
  font:
    700 14px/40px 'Roboto',
    sans-serif;
  border: 1px solid #99a2a8;
  text-align: center;
  transition: all 300ms linear 0s;
  color: #5f6771;
}
.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label i {
  font-size: 20px;
  padding-right: 10px;
}
.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label:hover,
.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label:focus {
  background: #daa520;
  border-color: #daa520;
  color: #fff;
}
.multi_step_form #msform fieldset .input-group .custom-file input {
  display: none;
}
.multi_step_form #msform fieldset .file_added {
  text-align: left;
  padding-left: 210px;
}
.multi_step_form #msform fieldset .file_added li {
  font:
    400 15px/28px 'Roboto',
    sans-serif;
  color: #5f6771;
}
.multi_step_form #msform fieldset .file_added li a {
  color: #daa520;
  font-weight: 500;
  display: inline-block;
  position: relative;
  padding-left: 15px;
}
.multi_step_form #msform fieldset .file_added li a i {
  font-size: 22px;
  padding-right: 8px;
  position: absolute;
  left: 0;
  transform: rotate(20deg);
}
.multi_step_form #msform #progressbar {
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
}
.multi_step_form #msform #progressbar li {
  list-style-type: none;
  color: #99a2a8;
  font-size: 9px;
  width: calc(100% / 5);
  float: left;
  position: relative;
  font:
    500 13px/1 'Roboto',
    sans-serif;
  cursor: pointer;
}
.multi_step_form #msform #progressbar li:nth-child(2):before {
  content: '\f31a';
}
.multi_step_form #msform #progressbar li:nth-child(3):before {
  content: '\f12a';
}
.multi_step_form #msform #progressbar li:nth-child(4):before {
  content: '\f3f4';
}
.multi_step_form #msform #progressbar li:nth-child(5):before {
  content: '\f371';
}
.multi_step_form #msform #progressbar li:before {
  content: '\f345';
  font: normal normal normal 30px/50px Ionicons;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  background: #f5f5f5;
  border-radius: 50%;
  margin: 0 auto 10px auto;
}
.multi_step_form #msform #progressbar li:after {
  content: '';
  width: 100%;
  height: 10px;
  background: #f5f5f5;
  position: absolute;
  left: -50%;
  top: 21px;
  z-index: -1;
}
.multi_step_form #msform #progressbar li:last-child:after {
  width: 150%;
}
.multi_step_form #msform #progressbar li.active ~ .multi_step_form #msform #progressbar li.active {
  color: #daa520;
}
.multi_step_form #msform #progressbar li.active:before,
.multi_step_form #msform #progressbar li.active:after {
  background: #daa520;
  color: white;
}
.multi_step_form #msform .action-button {
  background: #daa520;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 130px;
  font:
    700 14px/40px 'Roboto',
    sans-serif;
  border: 1px solid #daa520;
  margin: 0 5px;
  text-transform: uppercase;
  display: inline-block;
}
.multi_step_form #msform .action-button:hover,
.multi_step_form #msform .action-button:focus {
  background: #405867;
  border-color: #405867;
}
.multi_step_form #msform .previous_button {
  background: transparent;
  color: #99a2a8;
  border-color: #99a2a8;
}
.multi_step_form #msform .previous_button:hover,
.multi_step_form #msform .previous_button:focus {
  background: #c79006;
  border-color: #c79006;
  color: #fff;
}

.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
#file-7 {
  display: none;
}
.inputfile + label {
  max-width: 100%;
  font-size: 17px;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

/* style 6 */

.inputfile-6 + label {
  color: #daa520;
  border: 2px solid #eeeeee !important;
}

.inputfile-6 + label {
  background-color: #ffffff;
  padding: 0;
  width: 100%;
  max-width: 600px;
}

.inputfile-6:focus + label,
.inputfile-6.has-focus + label,
.inputfile-6 + label:hover {
  border-color: #daa520;
  border: 1px solid #daa520;
}

.inputfile-6 + label span,
.inputfile-6 + label strong {
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.inputfile-6 + label span {
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}

.inputfile-6 + label strong {
  height: 100%;
  color: #ffffff;
  background-color: #daa520;
  border: 1px solid #daa520;
  display: inline-block;
  float: right;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
  background-color: #c79006;
}

@media screen and (max-width: 50em) {
  .inputfile-6 + label strong {
    display: block;
  }
}

.react-tel-input {
  text-align: left;
}

/* phone filed */
.react-tel-input .flag-dropdown {
  display: none;
}

/* Boats */

.oddtable {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
}
.eventable {
  background-color: rgba(0, 0, 0, 0.025);
  padding: 10px;
}

/*registring radiop buttons*/
.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #daa520 !important;
  color: #ffffff;
  top: 5px;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 7px;
  outline: none;
}
.form-radio:checked::before {
  position: absolute;
  font:
    13px/1 'Open Sans',
    sans-serif;
  left: 6px;
  top: 2px;
  content: '\02143';
  transform: rotate(40deg);
}
.form-radio:hover {
  background-color: #c79006;
  color: #ffffff;
}
.form-radio:checked {
  background-color: #daa520;
}

.form-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #ffffff !important;
  color: #000000;
  top: 5px;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 1px;
  cursor: pointer;
  margin-right: 7px;
  outline: none;
  display: inline-flex !important;
}
.form-checkbox:checked::before {
  position: absolute;
  font:
    13px/1 'Open Sans',
    sans-serif;
  left: 6px;
  top: 2px;
  content: '\02143';
  transform: rotate(40deg);
}
.form-checkbox:hover {
  background-color: #c79006;
  color: #ffffff;
}
.form-checkbox:checked {
  background-color: #ffffff;
}

.form-check-input {
  border: 2px solid #daa520;
  padding: 0px;
  width: 18px;
  height: 18px;
}

.form-check-input:checked {
  background-color: #daa520;
  border-color: #daa520;
}

/*
checkbox
*/

.checkbox-label {
  display: inline-block;
}

.multiCheckBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
btn 
*/
.btn {
  margin: 4px;
}

.text-muted {
  padding: 0 5px;
}

.image-gallery {
  min-height: 200px;
  min-width: 100%;
}
