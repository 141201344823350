/* COLORS *
-----------------------------------------------------------------------*/
/* Grey dark */
/* Grey light */
/* Blue */
/* Green */
/* Pink */
/* Red */
/* Orange */
/* Black and White */
/* GLOBAL -------------------------------------------------*/
/* SCREENS --------------------------------------------------*/
html {
  background-color: white;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

h1 {
  font-size: 2.133em;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 1.8em;
  }
}

h2 {
  font-size: 2em;
  line-height: 1.2em;
  font-weight: 500;
}
@media only screen and (max-width: 768px) {
  h2 {
    font-size: 1.4em;
    line-height: 1.2em;
    margin-bottom: 20px;
  }
}

p {
  font-size: 1rem;
  line-height: 1.467em;
  color: #212529;
}
p span {
  font-weight: bold;
}

p.italic {
  font-style: italic;
}

a:hover {
  color: #979797;
  text-decoration: underline;
}

/* Button */
a.btn {
  font-size: 1rem;
  font-weight: 500;
  padding: 6px 12px;
  letter-spacing: 0.4px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
a.btn.btn-primary {
  background-color: #979797;
  color: #ffffff !important;
  border: 0;
  box-shadow: inset 0px -2px 0px 0px #127997;
  margin: 20px 20px 20px 0;
}
a.btn.btn-primary:hover,
a.btn.btn-primary:hover:active,
a.btn.btn-primary:active,
a.btn.btn-primary:focus,
a.btn.btn-primary:focus:active {
  background-color: #127997;
  box-shadow: inset 0px -2px 0px 0px #979797;
  cursor: pointer;
}
@media only screen and (max-width: 540px) {
  a.btn.btn-primary {
    display: block;
  }
}
a.btn.btn-default {
  background: #ffffff;
  border: 1px solid #4a4a4a;
  color: #4a4a4a;
}
a.btn.btn-default:hover,
a.btn.btn-default:hover:active,
a.btn.btn-default:active,
a.btn.btn-default:focus,
a.btn.btn-default:focus:active {
  background-color: #4a4a4a;
  color: #ffffff;
  border-color: none;
  cursor: pointer;
}

p.mandatory {
  opacity: 0.8;
  font-size: 0.9em;
  color: #4a4a4a;
  margin-bottom: 40px;
}

span.mandatory {
  margin: 0 0 0 16px;
  color: #f06060;
}

input.mandatory {
  border: 2px solid #f06060 !important;
}

ul {
  list-style-type: none;
  padding: 0;
}

.no-gutter {
  padding-right: 0;
  padding-left: 0;
}

.home-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1200px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

#modal .wizard-form {
  overflow: auto;
}

article {
  flex: 1 0 auto;
  background-color: #eee;
}
article:first-child {
  height: 100%;
}

section {
  display: flex;
}
@media only screen and (max-width: 540px) {
  section {
    padding: 20px 0;
  }
}

.light-grey {
  background: #eeeeee;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.white {
  background: #ffffff;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.dark-grey {
  background-color: #4a4a4a;
}

/* icons */
.icon-trash:before {
  content: '\f1f8 ';
  font-family: FontAwesome;
  font-size: 1, 2em;
  font-weight: normal;
}

/* messages */
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(24, 154, 191, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(24, 154, 191, 0.01);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(24, 154, 191, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0px 0px 0px 0px rgba(24, 154, 191, 0.6);
    box-shadow: 0px 0px 0px 0px #189abf;
  }
  70% {
    -moz-box-shadow: 0px 0px 0px 10px rgba(24, 154, 191, 0.01);
    box-shadow: 0px 0px 0px 10px rgba(24, 154, 191, 0.01);
  }
  100% {
    -moz-box-shadow: 0px 0px 0px 10px rgba(24, 154, 191, 0);
    box-shadow: 0px 0px 0px 10px rgba(24, 154, 191, 0);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(-50%) translateX(10px);
  }
}

.message {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.message p {
  font-weight: normal;
  margin: 0;
  color: #ffffff;
}
.message__orange {
  background: #ec5e27;
}
.message__orange p {
  margin: 0 0 0 30px;
}
.message__orange p:before {
  content: '\f06a';
  font-family: FontAwesome;
  color: #ffffff;
  font-size: 1.4em;
  position: absolute;
  left: 20px;
}
.message__red {
  background: #f06060;
}
.message__red p {
  margin: 0 0 0 30px;
}
.message__red p:before {
  content: '\f071';
  font-family: FontAwesome;
  color: #ffffff;
  font-size: 1.4em;
  position: absolute;
  left: 20px;
}
.message__green {
  background: #009669;
  animation: pulse 1.5s infinite;
  animation-iteration-count: 5;
  -moz-transform: scale(1) rotate(0deg) translateY(-1px);
  -webkit-transform: scale(1) rotate(0deg) translateY(-1px);
  transform: scale(1) rotate(0deg) translateY(-1px);
}
.message__green:hover {
  background: #007d57;
}
.message__green p:after {
  content: '\f00d';
  font-family: FontAwesome;
  color: #ffffff;
  font-size: 1.4em;
  position: absolute;
  right: 20px;
}
.message__blue {
  background: #979797;
  animation: pulse 1.5s infinite;
  animation-iteration-count: 5;
  -moz-transform: scale(1) rotate(0deg) translateY(-1px);
  -webkit-transform: scale(1) rotate(0deg) translateY(-1px);
  transform: scale(1) rotate(0deg) translateY(-1px);
}
.message__blue:hover {
  background: #127997;
}
.message__blue p {
  margin: 0 0 0 30px;
}
.message__blue p:before {
  content: '\f06a';
  font-family: FontAwesome;
  color: #ffffff;
  font-size: 1.4em;
  position: absolute;
  left: 20px;
}
.message__blue p:after {
  content: '\f105';
  font-family: FontAwesome;
  color: #ffffff;
  font-size: 1.4em;
  position: absolute;
  right: 30px;
  top: 30px;
  animation: bounce 1s linear infinite;
}

/*form*/
form {
  margin: 0;
}

label {
  color: #545454;
  display: block;
}
label p {
  margin: 0;
}
label span {
  font-weight: normal;
}
label.title {
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
label p {
  font-weight: normal;
}

input {
  font-family:
    'Arial',
    Helvetica Neue,
    Helvetica,
    sans-serif;
  border: 1px solid #d8d8d8;
  width: 100%;
  padding: 0 8px;
  margin: 0 0 10px;
  color: #545454;
  font-size: 15px;
  letter-spacing: 0.4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
input::placeholder {
  color: #6a6a6a;
}
input.invalid {
  border: 2px solid red;
  border-radius: 5px;
}
input:focus {
  outline: none;
  border: 1px solid #d8d8d8;
  box-shadow: 0 0 5px #979797;
}
input[type='radio'] {
  margin: 0;
}

.Select-control,
.select-style {
  padding: 0;
  max-width: 100%;
  padding: 2px 0;
  border-radius: 4px;
}
.Select-control:focus,
.select-style:focus {
  outline: none;
  border: 1px solid #d8d8d8;
  box-shadow: 0 0 5px #979797;
}
.Select-control input:focus,
.select-style input:focus {
  border: 0;
  box-shadow: 0 0 0;
}
.Select-control .Select-arrow,
.select-style .Select-arrow {
  color: #545454;
}
.Select-control .Select-input input,
.select-style .Select-input input {
  width: auto;
  padding: 0;
}
.Select-control .Select-placeholder,
.select-style .Select-placeholder {
  line-height: 40px;
}

.Select.is-focused:not(.is-open) > .Select-control {
  outline: none;
  border: 1px solid #d8d8d8;
  box-shadow: 0 0 5px #979797;
}

.select-style select {
  padding: 10px 15px;
  width: 100%;
  height: 40px;
  border: none;
  box-shadow: none;
  background: #ffffff;
  background-image: none;
  -webkit-appearance: none;
}
.select-style select:hover {
  cursor: pointer;
}

.Select--multi .Select-value {
  background-color: #daa520;
  border-radius: 2px;
  border: 1px solid #daa520;
  color: white !important;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  /* margin-top: 5px; */
  vertical-align: top;
  margin: 0 4px;
}

.Select--multi .Select-value-icon {
  border: 1px solid #daa520;
}

.select-style select:focus {
  outline: none;
}

/* REACT DATEPICKER */

/* Spinner */
.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 200;
}

.lds-spinner {
  font-size: 65px;
  color: official;
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0.4629em;
  top: 3px;
  width: 0.055em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background: #00a1cc;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pointer {
  cursor: pointer;
}

.ps-container > .ps-scrollbar-x-rail,
.ps-container > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

@media (min-width: 769px) {
  .news-letter {margin-top: -70px;}
}
