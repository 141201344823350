.admin-layout-container {
  .active {
    color: #daa520 !important;
  }
  .text-right {
    text-align: right;
  }

  .ion-ios-boat:before {
    content: '\f12a';
    font: normal normal normal 30px/50px Ionicons;
    width: 30px;
    height: 30px;
    line-height: 50px;
    display: block;
    margin: -10px auto 10px auto;
  }
}

.admin-layout-container .card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
  -webkit-transition:
    transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -moz-transition:
    transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -o-transition:
    transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -ms-transition:
    transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  transition:
    transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
}
.card .card-body {
  padding: 15px 15px 10px 15px;
}
.card .card-body.table-full-width {
  padding-left: 0;
  padding-right: 0;
}
.card .card-header {
  padding: 15px 15px 0;
  border: 0;
}
.card .card-header:not([data-background-color]) {
  background-color: transparent;
}
.card .card-header .card-title {
  margin-top: 10px;
}
.card .map {
  border-radius: 3px;
}
.card .map.map-big {
  height: 400px;
}
.card[data-background-color='orange'] {
  background-color: #51cbce;
}
.card[data-background-color='orange'] .card-header {
  background-color: #51cbce;
}
.card[data-background-color='orange'] .card-footer .stats {
  color: #ffffff;
}
.card[data-background-color='red'] {
  background-color: #ef8157;
}
.card[data-background-color='yellow'] {
  background-color: #fbc658;
}
.card[data-background-color='blue'] {
  background-color: #51bcda;
}
.card[data-background-color='green'] {
  background-color: #6bd098;
}
.card .image {
  overflow: hidden;
  height: 200px;
  position: relative;
}
.card .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
}
.card .message-avatar {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
}
.card .numbers {
  font-size: 2em;
}
.card .big-title {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  padding-bottom: 15px;
}
.card label {
  font-size: 0.8571em;
  margin-bottom: 5px;
  color: #9a9a9a;
}
.card .card-footer {
  background-color: transparent;
  border: 0;
}
.card .card-footer .stats i {
  margin-right: 5px;
  position: relative;
  top: 0px;
  color: #66615b;
}
.card .card-footer .btn {
  margin: 0;
}
.card.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}
.card.card-plain .card-body {
  padding-left: 5px;
  padding-right: 5px;
}
.card.card-plain img {
  border-radius: 12px;
}

.card-plain {
  background: transparent;
  box-shadow: none;
}
.card-plain .card-header,
.card-plain .card-footer {
  margin-left: 0;
  margin-right: 0;
  background-color: transparent;
}
.card-plain:not(.card-subcategories).card-body {
  padding-left: 0;
  padding-right: 0;
}

.card-chart .card-header .card-title {
  margin-top: 10px;
  margin-bottom: 0;
}

.card-chart .card-header .card-category {
  margin-bottom: 5px;
}

.card-chart .table {
  margin-bottom: 0;
}
.card-chart .table td {
  border-top: none;
  border-bottom: 1px solid #e9ecef;
}

.card-chart .card-progress {
  margin-top: 30px;
}

.card-chart .chart-area {
  height: 190px;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}

.card-chart .card-footer {
  margin-top: 15px;
}
.card-chart .card-footer .stats {
  color: #9a9a9a;
}

.card-chart .dropdown {
  position: absolute;
  right: 20px;
  top: 20px;
}
.card-chart .dropdown .btn {
  margin: 0;
}

.card-user .image {
  height: 130px;
}
.card-user .image img {
  border-radius: 12px;
}

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -77px;
}
.card-user .author a + p.description {
  margin-top: -7px;
}

.card-user .avatar {
  width: 124px;
  height: 124px;
  border: 1px solid #ffffff;
  position: relative;
  object-fit: cover;
}

.card-user .card-body {
  min-height: 100px;
}

.card-user hr {
  margin: 5px 15px 15px;
}

.card-user .card-body + .card-footer {
  padding-top: 0;
}

.card-user .card-footer h5 {
  font-size: 1.25em;
  margin-bottom: 0;
}

.card-user .button-container {
  margin-bottom: 6px;
  text-align: center;
}

.map {
  height: 500px;
}

.card-stats .card-body {
  padding: 15px 15px 0px;
}
.card-stats .card-body .numbers {
  text-align: right;
  font-size: 2em;
}
.card-stats .card-body .numbers p {
  margin-bottom: 0;
}
.card-stats .card-body .numbers .card-category {
  color: #9a9a9a;
  font-size: 16px;
  line-height: 1.4em;
}

.card-stats .card-footer {
  padding: 0px 15px 15px;
}
.card-stats .card-footer .stats {
  color: #9a9a9a;
}
.card-stats .card-footer hr {
  margin-top: 10px;
  margin-bottom: 15px;
}

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px;
}
.card-stats .icon-big i {
  line-height: 59px;
}

.admin-layout-container {
  @media screen and (max-width: 991px) {
    .navbar {
      padding-right: 20px;
      padding-top: 1.5rem;
      padding-bottom: 0.625rem;
      min-height: 53px;
      margin-bottom: 20px;
    }
    .navbar.navbar-absolute {
      padding-top: 0;
    }
    .navbar .navbar-brand {
      font-size: 16px;
      margin-right: 0;
    }
    .profile-photo .profile-photo-small {
      margin-left: -2px;
    }
    .button-dropdown {
      display: none;
    }
    #minimizeSidebar {
      display: none;
    }
    .navbar .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }
    .navbar .navbar-collapse .input-group {
      margin: 0;
      margin-top: 5px;
    }
    .navbar .navbar-nav .nav-item:first-child {
      margin-top: 10px;
    }
    .navbar .navbar-nav .nav-item:not(:last-child) {
      margin-bottom: 10px;
    }
    .navbar .dropdown.show .dropdown-menu {
      display: block;
    }
    .navbar .dropdown .dropdown-menu {
      display: none;
    }
    .navbar .dropdown.show .dropdown-menu,
    .navbar .dropdown .dropdown-menu {
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      width: auto;
      margin: 0px 1rem;
      margin-top: 0px;
      box-shadow: none;
      position: static;
      padding-left: 10px;
    }
    .navbar .dropdown.show .dropdown-menu:before,
    .navbar .dropdown .dropdown-menu:before {
      display: none;
    }
    .navbar .dropdown-menu .dropdown-item:focus,
    .navbar .dropdown-menu .dropdown-item:hover {
      color: #ffffff;
    }
    .navbar.bg-white .dropdown-menu .dropdown-item:focus,
    .navbar.bg-white .dropdown-menu .dropdown-item:hover {
      color: #66615b;
    }
    .navbar .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: #66615b;
    }
    .navbar .navbar-toggler-bar + .navbar-toggler-bar {
      margin-top: 7px;
    }
    .navbar .navbar-toggler-bar + .navbar-toggler-bar.navbar-kebab {
      margin-top: 3px;
    }
    .navbar .navbar-toggler-bar.bar2 {
      width: 17px;
      transition: width 0.2s linear;
    }
    .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: #66615b;
    }
    .navbar .toggled .navbar-toggler-bar {
      width: 24px;
    }
    .navbar .toggled .navbar-toggler-bar + .navbar-toggler-bar {
      margin-top: 5px;
    }
    .wrapper {
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .nav-open .main-panel {
      right: 0;
      -webkit-transform: translate3d(260px, 0, 0);
      -moz-transform: translate3d(260px, 0, 0);
      -o-transform: translate3d(260px, 0, 0);
      -ms-transform: translate3d(260px, 0, 0);
      transform: translate3d(260px, 0, 0);
    }
    .nav-open .sidebar {
      -webkit-transform: translate3d(0px, 0, 0);
      -moz-transform: translate3d(0px, 0, 0);
      -o-transform: translate3d(0px, 0, 0);
      -ms-transform: translate3d(0px, 0, 0);
      transform: translate3d(0px, 0, 0);
    }
    .nav-open body {
      position: relative;
      overflow-x: hidden;
    }
    .nav-open .menu-on-right .main-panel {
      -webkit-transform: translate3d(-260px, 0, 0);
      -moz-transform: translate3d(-260px, 0, 0);
      -o-transform: translate3d(-260px, 0, 0);
      -ms-transform: translate3d(-260px, 0, 0);
      transform: translate3d(-260px, 0, 0);
    }
    .nav-open .menu-on-right .navbar-collapse,
    .nav-open .menu-on-right .sidebar {
      -webkit-transform: translate3d(0px, 0, 0);
      -moz-transform: translate3d(0px, 0, 0);
      -o-transform: translate3d(0px, 0, 0);
      -ms-transform: translate3d(0px, 0, 0);
      transform: translate3d(0px, 0, 0);
    }
    .nav-open .menu-on-right .navbar-translate {
      -webkit-transform: translate3d(-300px, 0, 0);
      -moz-transform: translate3d(-300px, 0, 0);
      -o-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }
    .nav-open .menu-on-right #bodyClick {
      right: 260px;
      left: auto;
    }
    .menu-on-right .sidebar {
      left: auto;
      right: 0;
      -webkit-transform: translate3d(260px, 0, 0);
      -moz-transform: translate3d(260px, 0, 0);
      -o-transform: translate3d(260px, 0, 0);
      -ms-transform: translate3d(260px, 0, 0);
      transform: translate3d(260px, 0, 0);
    }
    .bar1,
    .bar2,
    .bar3 {
      outline: 1px solid transparent;
    }
    .bar1 {
      top: 0px;
      -webkit-animation: topbar-back 500ms linear 0s;
      -moz-animation: topbar-back 500ms linear 0s;
      animation: topbar-back 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    .bar2 {
      opacity: 1;
    }
    .bar3 {
      bottom: 0px;
      -webkit-animation: bottombar-back 500ms linear 0s;
      -moz-animation: bottombar-back 500ms linear 0s;
      animation: bottombar-back 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    .toggled .bar1 {
      top: 6px;
      -webkit-animation: topbar-x 500ms linear 0s;
      -moz-animation: topbar-x 500ms linear 0s;
      animation: topbar-x 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    .toggled .bar2 {
      opacity: 0;
    }
    .toggled .bar3 {
      bottom: 6px;
      -webkit-animation: bottombar-x 500ms linear 0s;
      -moz-animation: bottombar-x 500ms linear 0s;
      animation: bottombar-x 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    @keyframes topbar-x {
      0% {
        top: 0px;
        transform: rotate(0deg);
      }
      45% {
        top: 6px;
        transform: rotate(145deg);
      }
      75% {
        transform: rotate(130deg);
      }
      100% {
        transform: rotate(135deg);
      }
    }
    @-webkit-keyframes topbar-x {
      0% {
        top: 0px;
        -webkit-transform: rotate(0deg);
      }
      45% {
        top: 6px;
        -webkit-transform: rotate(145deg);
      }
      75% {
        -webkit-transform: rotate(130deg);
      }
      100% {
        -webkit-transform: rotate(135deg);
      }
    }
    @-moz-keyframes topbar-x {
      0% {
        top: 0px;
        -moz-transform: rotate(0deg);
      }
      45% {
        top: 6px;
        -moz-transform: rotate(145deg);
      }
      75% {
        -moz-transform: rotate(130deg);
      }
      100% {
        -moz-transform: rotate(135deg);
      }
    }
    @keyframes topbar-back {
      0% {
        top: 6px;
        transform: rotate(135deg);
      }
      45% {
        transform: rotate(-10deg);
      }
      75% {
        transform: rotate(5deg);
      }
      100% {
        top: 0px;
        transform: rotate(0);
      }
    }
    @-webkit-keyframes topbar-back {
      0% {
        top: 6px;
        -webkit-transform: rotate(135deg);
      }
      45% {
        -webkit-transform: rotate(-10deg);
      }
      75% {
        -webkit-transform: rotate(5deg);
      }
      100% {
        top: 0px;
        -webkit-transform: rotate(0);
      }
    }
    @-moz-keyframes topbar-back {
      0% {
        top: 6px;
        -moz-transform: rotate(135deg);
      }
      45% {
        -moz-transform: rotate(-10deg);
      }
      75% {
        -moz-transform: rotate(5deg);
      }
      100% {
        top: 0px;
        -moz-transform: rotate(0);
      }
    }
    @keyframes bottombar-x {
      0% {
        bottom: 0px;
        transform: rotate(0deg);
      }
      45% {
        bottom: 6px;
        transform: rotate(-145deg);
      }
      75% {
        transform: rotate(-130deg);
      }
      100% {
        transform: rotate(-135deg);
      }
    }
    @-webkit-keyframes bottombar-x {
      0% {
        bottom: 0px;
        -webkit-transform: rotate(0deg);
      }
      45% {
        bottom: 6px;
        -webkit-transform: rotate(-145deg);
      }
      75% {
        -webkit-transform: rotate(-130deg);
      }
      100% {
        -webkit-transform: rotate(-135deg);
      }
    }
    @-moz-keyframes bottombar-x {
      0% {
        bottom: 0px;
        -moz-transform: rotate(0deg);
      }
      45% {
        bottom: 6px;
        -moz-transform: rotate(-145deg);
      }
      75% {
        -moz-transform: rotate(-130deg);
      }
      100% {
        -moz-transform: rotate(-135deg);
      }
    }
    @keyframes bottombar-back {
      0% {
        bottom: 6px;
        transform: rotate(-135deg);
      }
      45% {
        transform: rotate(10deg);
      }
      75% {
        transform: rotate(-5deg);
      }
      100% {
        bottom: 0px;
        transform: rotate(0);
      }
    }
    @-webkit-keyframes bottombar-back {
      0% {
        bottom: 6px;
        -webkit-transform: rotate(-135deg);
      }
      45% {
        -webkit-transform: rotate(10deg);
      }
      75% {
        -webkit-transform: rotate(-5deg);
      }
      100% {
        bottom: 0px;
        -webkit-transform: rotate(0);
      }
    }
    @-moz-keyframes bottombar-back {
      0% {
        bottom: 6px;
        -moz-transform: rotate(-135deg);
      }
      45% {
        -moz-transform: rotate(10deg);
      }
      75% {
        -moz-transform: rotate(-5deg);
      }
      100% {
        bottom: 0px;
        -moz-transform: rotate(0);
      }
    }
    @-webkit-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @-moz-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    #bodyClick {
      height: 100%;
      width: 100%;
      position: fixed;
      opacity: 1;
      top: 0;
      right: 0;
      left: 260px;
      content: '';
      z-index: 9999;
      overflow-x: hidden;
      background-color: transparent;
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .footer .copyright {
      text-align: right;
    }
    .section-nucleo-icons .icons-container {
      margin-top: 65px;
    }
    .navbar-nav .nav-link i.fa,
    .navbar-nav .nav-link i.nc-icon {
      opacity: 0.5;
    }
    .sidebar,
    .bootstrap-navbar {
      position: fixed;
      display: block;
      top: 0;
      height: 100%;
      width: 260px;
      right: auto;
      left: 0;
      z-index: 1032;
      visibility: visible;
      overflow-y: visible;
      padding: 0;
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -webkit-transform: translate3d(-260px, 0, 0);
      -moz-transform: translate3d(-260px, 0, 0);
      -o-transform: translate3d(-260px, 0, 0);
      -ms-transform: translate3d(-260px, 0, 0);
      transform: translate3d(-260px, 0, 0);
    }
    .main-panel {
      width: 100%;
    }
    .timeline:before {
      left: 5% !important;
    }
    .timeline > li > .timeline-badge {
      left: 5% !important;
    }
    .timeline > li > .timeline-panel {
      float: right !important;
      width: 82% !important;
    }
    .timeline > li > .timeline-panel:before {
      border-left-width: 0 !important;
      border-right-width: 15px !important;
      left: -15px !important;
      right: auto !important;
    }
    .timeline > li > .timeline-panel:after {
      border-left-width: 0 !important;
      border-right-width: 14px !important;
      left: -14px !important;
      right: auto !important;
    }
  }

  @media (max-width: 991px) and (min-width: 768px) {
    .nav-tabs-navigation.verical-navs {
      padding: 0px 2px;
    }
  }

  @media screen and (min-width: 768px) {
    .footer .footer-nav {
      padding-left: 21px;
    }
    .footer .credits {
      padding-right: 15px;
    }
  }

  @media screen and (min-width: 992px) {
    .navbar-collapse {
      background: none !important;
    }
    .navbar .navbar-toggle {
      display: none;
    }
    .navbar-nav .nav-link.profile-photo {
      padding: 0;
      margin: 7px 0.7rem;
    }
    .section-nucleo-icons .icons-container {
      margin: 0 0 0 auto;
    }
    .dropdown-menu .dropdown-item {
      color: inherit;
    }
    .footer .copyright {
      float: right;
      padding-right: 15px;
    }
    .sidebar .sidebar-wrapper li.active > a:not([data-toggle='collapse']):before,
    .sidebar .sidebar-wrapper li.active > [data-toggle='collapse'] + div .nav li:before {
      border-right: 17px solid #dddddd;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      content: '';
      display: inline-block;
      position: absolute;
      right: -16px;
      opacity: 1;
      top: 7px;
      transition: opacity 150ms ease-in;
    }
    .sidebar .sidebar-wrapper li.active > a:not([data-toggle='collapse']):after,
    .sidebar .sidebar-wrapper li.active > [data-toggle='collapse'] + div .nav li:after {
      border-right: 17px solid #f4f3ef;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      content: '';
      display: inline-block;
      position: absolute;
      right: -17px;
      opacity: 1;
      top: 7px;
      transition: opacity 150ms ease-in;
    }
    .sidebar .sidebar-wrapper li.active > [data-toggle='collapse'] + div .nav li a:before,
    .sidebar .sidebar-wrapper li.active > [data-toggle='collapse'] + div .nav li a:after {
      top: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .card-stats [class*='col-'] .statistics::after {
      display: none;
    }
    .main-panel .content {
      padding-left: 15px;
      padding-right: 15px;
    }
    .footer nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
    .landing-page .section-story-overview .image-container:nth-child(2) {
      margin-left: 0;
      margin-bottom: 30px;
    }
    .card .form-horizontal .col-md-3.col-form-label {
      text-align: left;
    }
  }

  @media screen and (max-width: 767px) {
    .nav-tabs-navigation.verical-navs {
      padding: 0 28px;
    }
    .typography-line {
      padding-left: 23% !important;
    }
    .typography-line span {
      width: 60px !important;
    }
    .login-page .navbar,
    .lock-page .navbar,
    .register-page .navbar {
      padding: 0.5rem 1rem;
    }
    .footer .footer-nav,
    .footer .credits {
      margin: 0 auto !important;
    }
    .footer .footer-nav {
      margin-bottom: 10px !important;
    }
    .register-page .content {
      padding-top: 5vh;
    }
    .register-page .footer {
      position: relative;
    }
    .register-page .info-area.info-horizontal {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 374px) {
    .login-page .content {
      padding-top: 10vh;
    }
  }

  @media screen and (max-width: 413px) {
    .fixed-plugin .dropdown.show-dropdown.show .dropdown-menu.show {
      width: 225px !important;
    }
    .fixed-plugin .dropdown.show-dropdown.show .dropdown-menu.show[x-placement='top-start'] {
      transform: translate3d(0, 400px, 0) !important;
    }
    .fixed-plugin .dropdown.show-dropdown.show .dropdown-menu.show:before,
    .fixed-plugin .dropdown.show-dropdown.show .dropdown-menu.show:after {
      bottom: 360px !important;
    }
  }

  @media screen and (max-width: 576px) {
    .navbar[class*='navbar-toggleable-'] .container {
      margin-left: 0;
      margin-right: 0;
    }
    .card-contributions .card-stats {
      flex-direction: column;
    }
    .card-contributions .card-stats .bootstrap-switch {
      margin-bottom: 15px;
    }

    .section-nucleo-icons .icons-container i {
      font-size: 30px;
    }
    .section-nucleo-icons .icons-container i:nth-child(6) {
      font-size: 48px;
    }
    .page-header .container h6.category-absolute {
      width: 90%;
    }
    .card-timeline .timeline .timeline-panel {
      width: 38%;
      padding: 15px;
    }
  }

  .alert .close {
    font-weight: 300;
    font-size: 29px;
  }
  .alert .close i.fa,
  .alert .close i.fas,
  .alert .close i.far,
  .alert .close i.fal,
  .alert .close i.fab,
  .alert .close i.nc-icon {
    font-size: 14px !important;
  }

  .btn.btn-icon.btn-sm .fa,
  .btn.btn-icon.btn-sm .far,
  .btn.btn-icon.btn-sm .fas,
  .btn.btn-icon.btn-sm .fal,
  .btn.btn-icon.btn-sm .fab,
  .btn.btn-icon.btn-sm .nc-icon,
  .navbar .navbar-nav > a.btn.btn-icon.btn-sm .fa,
  .navbar .navbar-nav > a.btn.btn-icon.btn-sm .far,
  .navbar .navbar-nav > a.btn.btn-icon.btn-sm .fas,
  .navbar .navbar-nav > a.btn.btn-icon.btn-sm .fal,
  .navbar .navbar-nav > a.btn.btn-icon.btn-sm .fab,
  .navbar .navbar-nav > a.btn.btn-icon.btn-sm .nc-icon {
    font-size: 0.6875rem;
  }

  .btn.btn-icon.btn-lg .fa,
  .btn.btn-icon.btn-lg .far,
  .btn.btn-icon.btn-lg .fas,
  .btn.btn-icon.btn-lg .fal,
  .btn.btn-icon.btn-lg .fab,
  .btn.btn-icon.btn-lg .nc-icon,
  .navbar .navbar-nav > a.btn.btn-icon.btn-lg .fa,
  .navbar .navbar-nav > a.btn.btn-icon.btn-lg .far,
  .navbar .navbar-nav > a.btn.btn-icon.btn-lg .fas,
  .navbar .navbar-nav > a.btn.btn-icon.btn-lg .fal,
  .navbar .navbar-nav > a.btn.btn-icon.btn-lg .fab,
  .navbar .navbar-nav > a.btn.btn-icon.btn-lg .nc-icon {
    font-size: 1.325rem;
  }

  .btn.btn-icon:not(.btn-footer) .nc-icon,
  .btn.btn-icon:not(.btn-footer) .fa,
  .btn.btn-icon:not(.btn-footer) .far,
  .btn.btn-icon:not(.btn-footer) .fas,
  .btn.btn-icon:not(.btn-footer) .fal,
  .btn.btn-icon:not(.btn-footer) .fas,
  .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .nc-icon,
  .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .fa,
  .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .far,
  .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .fas,
  .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .fal,
  .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 1.5626rem;
    width: 24px;
  }

  .form-check .form-check-sign {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }

  .form-check-radio .form-check-sign {
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
  .form-check-radio .form-check-sign::before {
    font-size: 22px;
    content: '\f111';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    position: absolute;
    opacity: 0.5;
    left: 5px;
    top: -5px;
  }

  .form-check .form-check-sign:after {
    font-family: 'Font Awesome 5 Free';
    top: 4px;
  }

  .form-check-radio .form-check-sign::after,
  .form-check-radio input[type='radio']:checked + .form-check-sign::after {
    font-family: 'Font Awesome 5 Free';
    top: -1px;
  }

  .form-check-radio .form-check-sign::before,
  .form-check-radio input[type='radio']:checked + .form-check-sign::before {
    font-family: 'Font Awesome 5 Free';
    top: -1px;
  }

  .dropup .dropdown-toggle:after,
  .dropdown .dropdown-toggle:after {
    margin-left: 3px;
  }

  .fixed-plugin .dropdown .dropdown-menu:before {
    right: -17px;
    top: 28%;
  }

  .fixed-plugin .dropdown .dropdown-menu:after {
    right: -16px;
    top: 28%;
  }

  .fixed-plugin .gh-btn,
  .fixed-plugin .gh-count,
  .fixed-plugin .gh-ico {
    float: left;
  }

  .fixed-plugin .gh-btn,
  .fixed-plugin .gh-count {
    padding: 2px 5px 2px 4px;
    color: #333;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 3px;
  }

  .fixed-plugin .gh-btn {
    background-color: #eee;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #fcfcfc),
      color-stop(100%, #eee)
    );
    background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
    background-repeat: no-repeat;
    border: 1px solid #d5d5d5;
  }

  .fixed-plugin .gh-btn:hover,
  .fixed-plugin .gh-btn:focus {
    text-decoration: none;
    background-color: #ddd;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #eee),
      color-stop(100%, #ddd)
    );
    background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
    border-color: #ccc;
  }

  .fixed-plugin .gh-btn:active {
    background-image: none;
    background-color: #dcdcdc;
    border-color: #b5b5b5;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
  }

  .fixed-plugin .gh-ico {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .fixed-plugin .gh-count {
    position: relative;
    display: none;
    /* hidden to start */
    margin-left: 4px;
    background-color: #fafafa;
    border: 1px solid #d4d4d4;
  }

  .fixed-plugin .gh-count:hover,
  .fixed-plugin .gh-count:focus {
    color: #4183c4;
  }

  .fixed-plugin .gh-count:before,
  .fixed-plugin .gh-count:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  .fixed-plugin .gh-count:before {
    top: 50%;
    left: -3px;
    margin-top: -4px;
    border-width: 4px 4px 4px 0;
    border-right-color: #fafafa;
  }

  .fixed-plugin .gh-count:after {
    top: 50%;
    left: -4px;
    z-index: -1;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #d4d4d4;
  }

  .fixed-plugin .github-btn-large {
    height: 30px;
  }

  .fixed-plugin .github-btn-large .gh-btn,
  .fixed-plugin .github-btn-large .gh-count {
    padding: 3px 10px 3px 8px;
    font-size: 16px;
    line-height: 22px;
    border-radius: 4px;
  }

  .fixed-plugin .github-btn-large .gh-ico {
    width: 20px;
    height: 20px;
  }

  .fixed-plugin .github-btn-large .gh-count {
    margin-left: 6px;
  }

  .fixed-plugin .github-btn-large .gh-count:before {
    left: -5px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
  }

  .fixed-plugin .github-btn-large .gh-count:after {
    left: -6px;
    margin-top: -7px;
    border-width: 7px 7px 7px 0;
  }

  .fixed-plugin .github-btn {
    display: inline-block;
  }
  .fixed-plugin .github-btn .gh-btn .gh-ico {
    margin-top: 3px;
  }

  .fixed-plugin .SocialMediaShareButton {
    display: inline-block;
  }

  @media screen and (max-width: 400px) {
    .fixed-plugin .dropdown-menu {
      width: 250px;
    }
  }

  select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 4px);
  }

  .form-control {
    height: unset;
  }

  .form-group input[type='file'] {
    opacity: 1;
    position: unset;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: unset;
    z-index: unset;
  }

  .navbar .navbar-nav .nav-link i.fa + p,
  .navbar .navbar-nav .nav-link i.fal + p,
  .navbar .navbar-nav .nav-link i.fas + p,
  .navbar .navbar-nav .nav-link i.fab + p,
  .navbar .navbar-nav .nav-link i.far + p,
  .navbar .navbar-nav .nav-link i.nc-icon + p {
    margin-left: 7px;
  }

  .navbar .navbar-nav .nav-link i.fa,
  .navbar .navbar-nav .nav-link i.fal,
  .navbar .navbar-nav .nav-link i.fas,
  .navbar .navbar-nav .nav-link i.fab,
  .navbar .navbar-nav .nav-link i.far,
  .navbar .navbar-nav .nav-link i.nc-icon {
    font-size: 18px;
    position: relative;
    top: 3px;
    text-align: center;
    width: 21px;
  }

  /*--------------------------------
  
  nucleo-icons Web Font - built using nucleoapp.com
  License - nucleoapp.com/license/
  
  -------------------------------- */

  @media screen and (max-width: 991px) {
    .navbar-nav .nav-link i.fa,
    .navbar-nav .nav-link i.fal,
    .navbar-nav .nav-link i.fas,
    .navbar-nav .nav-link i.fab,
    .navbar-nav .nav-link i.far,
    .navbar-nav .nav-link i.nc-icon {
      opacity: 0.5;
    }
    .navbar .dropdown.show .dropdown-menu {
      border: 0;
      -webkit-transition: none;
      transition: none;
      -webkit-box-shadow: none;
      width: auto;
      margin: 0 1rem;
      box-shadow: none;
      position: static !important;
      padding-left: 10px;
      display: block !important;
      -webkit-transform: translateZ(0) !important;
      transform: translateZ(0) !important;
      background: inherit;
    }
    .navbar .dropdown.show .dropdown-menu:before,
    .navbar .dropdown.show .dropdown-menu:after {
      display: none;
    }
    .navbar.bg-dark .dropdown.show .dropdown-menu a {
      color: #ffffff;
    }
  }

  @media screen and (min-width: 992px) {
    .sidebar .sidebar-wrapper li.active > a:not([data-toggle='collapse']):before {
      border-right: 18px solid #dddddd;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      right: -15px;
      top: 6px;
    }
    .sidebar .sidebar-wrapper li.active > a:not([data-toggle='collapse']):after {
      border-right: 17px solid #f4f3ef;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      right: -15px;
      top: 7px;
    }
  }

  *,
  *:focus,
  a,
  a:focus {
    outline: none;
  }
}

@font-face {
  font-family: 'nucleo-icons';
  src: url('./fonts/nucleo-icons.eot');
  src:
    url('./fonts/nucleo-icons.eot') format('embedded-opentype'),
    url('./fonts/nucleo-icons.woff2') format('woff2'),
    url('./fonts/nucleo-icons.woff') format('woff'),
    url('./fonts/nucleo-icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/
.nc-icon {
  display: inline-block;
  font: normal normal normal 14px/1 'nucleo-icons';
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/
.nc-icon.lg {
  font-size: 1.33333333em;
  vertical-align: -16%;
}

.nc-icon.x2 {
  font-size: 2em;
}

.nc-icon.x3 {
  font-size: 3em;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.nc-icon.square,
.nc-icon.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee;
}

.nc-icon.circle {
  border-radius: 50%;
}

/*------------------------
  list icons
-------------------------*/
.nc-icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.nc-icon-ul > li {
  position: relative;
}

.nc-icon-ul > li > .nc-icon {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center;
}

.nc-icon-ul > li > .nc-icon.lg {
  top: 0;
  left: -1.35714286em;
}

.nc-icon-ul > li > .nc-icon.circle,
.nc-icon-ul > li > .nc-icon.square {
  top: -0.19047619em;
  left: -1.9047619em;
}

/*------------------------
  spinning icons
-------------------------*/
.nc-icon.spin {
  -webkit-animation: nc-icon-spin 2s infinite linear;
  -moz-animation: nc-icon-spin 2s infinite linear;
  animation: nc-icon-spin 2s infinite linear;
}

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/
.nc-icon.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.nc-icon.rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.nc-icon.rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.nc-icon.flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.nc-icon.flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}

/*------------------------
	font icons
-------------------------*/
.nc-air-baloon::before {
  content: '\ea01';
}

.nc-album-2::before {
  content: '\ea02';
}

.nc-alert-circle-i::before {
  content: '\ea04';
}

.nc-align-center::before {
  content: '\ea03';
}

.nc-align-left-2::before {
  content: '\ea05';
}

.nc-ambulance::before {
  content: '\ea06';
}

.nc-app::before {
  content: '\ea07';
}

.nc-atom::before {
  content: '\ea08';
}

.nc-badge::before {
  content: '\ea09';
}

.nc-bag-16::before {
  content: '\ea0a';
}

.nc-bank::before {
  content: '\ea0b';
}

.nc-basket::before {
  content: '\ea0c';
}

.nc-bell-55::before {
  content: '\ea0d';
}

.nc-bold::before {
  content: '\ea0e';
}

.nc-book-bookmark::before {
  content: '\ea0f';
}

.nc-bookmark-2::before {
  content: '\ea10';
}

.nc-box-2::before {
  content: '\ea11';
}

.nc-box::before {
  content: '\ea12';
}

.nc-briefcase-24::before {
  content: '\ea13';
}

.nc-bulb-63::before {
  content: '\ea14';
}

.nc-bullet-list-67::before {
  content: '\ea15';
}

.nc-bus-front-12::before {
  content: '\ea16';
}

.nc-button-pause::before {
  content: '\ea17';
}

.nc-button-play::before {
  content: '\ea18';
}

.nc-button-power::before {
  content: '\ea19';
}

.nc-calendar-60::before {
  content: '\ea1a';
}

.nc-camera-compact::before {
  content: '\ea1b';
}

.nc-caps-small::before {
  content: '\ea1c';
}

.nc-cart-simple::before {
  content: '\ea1d';
}

.nc-chart-bar-32::before {
  content: '\ea1e';
}

.nc-chart-pie-36::before {
  content: '\ea1f';
}

.nc-chat-33::before {
  content: '\ea20';
}

.nc-check-2::before {
  content: '\ea21';
}

.nc-circle-10::before {
  content: '\ea22';
}

.nc-cloud-download-93::before {
  content: '\ea23';
}

.nc-cloud-upload-94::before {
  content: '\ea24';
}

.nc-compass-05::before {
  content: '\ea25';
}

.nc-controller-modern::before {
  content: '\ea26';
}

.nc-credit-card::before {
  content: '\ea27';
}

.nc-delivery-fast::before {
  content: '\ea28';
}

.nc-diamond::before {
  content: '\ea29';
}

.nc-email-85::before {
  content: '\ea2a';
}

.nc-favourite-28::before {
  content: '\ea2b';
}

.nc-glasses-2::before {
  content: '\ea2c';
}

.nc-globe-2::before {
  content: '\ea2d';
}

.nc-globe::before {
  content: '\ea2e';
}

.nc-hat-3::before {
  content: '\ea2f';
}

.nc-headphones::before {
  content: '\ea30';
}

.nc-html5::before {
  content: '\ea31';
}

.nc-image::before {
  content: '\ea32';
}

.nc-istanbul::before {
  content: '\ea33';
}

.nc-key-25::before {
  content: '\ea34';
}

.nc-laptop::before {
  content: '\ea35';
}

.nc-layout-11::before {
  content: '\ea36';
}

.nc-lock-circle-open::before {
  content: '\ea37';
}

.nc-map-big::before {
  content: '\ea38';
}

.nc-minimal-down::before {
  content: '\ea39';
}

.nc-minimal-left::before {
  content: '\ea3a';
}

.nc-minimal-right::before {
  content: '\ea3b';
}

.nc-minimal-up::before {
  content: '\ea3c';
}

.nc-mobile::before {
  content: '\ea3d';
}

.nc-money-coins::before {
  content: '\ea3e';
}

.nc-note-03::before {
  content: '\ea3f';
}

.nc-palette::before {
  content: '\ea40';
}

.nc-paper::before {
  content: '\ea41';
}

.nc-pin-3::before {
  content: '\ea42';
}

.nc-planet::before {
  content: '\ea43';
}

.nc-refresh-69::before {
  content: '\ea44';
}

.nc-ruler-pencil::before {
  content: '\ea45';
}

.nc-satisfied::before {
  content: '\ea46';
}

.nc-scissors::before {
  content: '\ea47';
}

.nc-send::before {
  content: '\ea48';
}

.nc-settings-gear-65::before {
  content: '\ea49';
}

.nc-settings::before {
  content: '\ea4a';
}

.nc-share-66::before {
  content: '\ea4b';
}

.nc-shop::before {
  content: '\ea4c';
}

.nc-simple-add::before {
  content: '\ea4d';
}

.nc-simple-delete::before {
  content: '\ea4e';
}

.nc-simple-remove::before {
  content: '\ea4f';
}

.nc-single-02::before {
  content: '\ea50';
}

.nc-single-copy-04::before {
  content: '\ea51';
}

.nc-sound-wave::before {
  content: '\ea52';
}

.nc-spaceship::before {
  content: '\ea53';
}

.nc-sun-fog-29::before {
  content: '\ea54';
}

.nc-support-17::before {
  content: '\ea55';
}

.nc-tablet-2::before {
  content: '\ea56';
}

.nc-tag-content::before {
  content: '\ea57';
}

.nc-tap-01::before {
  content: '\ea58';
}

.nc-tie-bow::before {
  content: '\ea59';
}

.nc-tile-56::before {
  content: '\ea5a';
}

.nc-time-alarm::before {
  content: '\ea5b';
}

.nc-touch-id::before {
  content: '\ea5c';
}

.nc-trophy::before {
  content: '\ea5d';
}

.nc-tv-2::before {
  content: '\ea5e';
}

.nc-umbrella-13::before {
  content: '\ea5f';
}

.nc-user-run::before {
  content: '\ea60';
}

.nc-vector::before {
  content: '\ea61';
}

.nc-watch-time::before {
  content: '\ea62';
}

.nc-world-2::before {
  content: '\ea63';
}

.nc-zoom-split::before {
  content: '\ea64';
}

.admin-layout-container form .form-control {
  border-radius: 3px;
  border: 1px solid #d8e1e7;
  padding: 0 20px;
  height: auto;
  font:
    400 15px/48px 'Roboto',
    sans-serif;
  color: #5f6771;
  box-shadow: none;
  outline: none;
  width: 100%;
}

.admin-layout-container form .product_select {
  border-radius: 3px;
  border: 1px solid #d8e1e7;
  height: auto;
  font:
    400 15px/48px 'Roboto',
    sans-serif;
  color: #5f6771;
  box-shadow: none;
  outline: none;
  width: 100%;
}

.admin-layout-container form fieldset .product_select input,
.admin-layout-container form .product_select input:focus {
  border: 0px;
  box-shadow: none;
}

.admin-layout-container form .form-control.placeholder,
.admin-layout-container form .product_select.placeholder {
  color: #5f6771;
}
.admin-layout-container form .form-control:-moz-placeholder,
.admin-layout-container form .product_select:-moz-placeholder {
  color: #5f6771;
}
.admin-layout-container form .form-control::-moz-placeholder,
.admin-layout-container form .product_select::-moz-placeholder {
  color: #5f6771;
}
.admin-layout-container form .form-control::-webkit-input-placeholder,
.admin-layout-container form .product_select::-webkit-input-placeholder {
  color: #5f6771;
}
.admin-layout-container form .form-control:hover,
.admin-layout-container form .form-control:focus,
.admin-layout-container form .product_select:hover,
.admin-layout-container form .product_select:focus {
  border-color: #daa520;
}
.admin-layout-container form .form-control:focus.placeholder,
.admin-layout-container form .product_select:focus.placeholder {
  color: transparent;
}
.admin-layout-container form .form-control:focus:-moz-placeholder,
.admin-layout-container form .product_select:focus:-moz-placeholder {
  color: transparent;
}
.admin-layout-container form .form-control:focus::-moz-placeholder,
.admin-layout-container form .product_select:focus::-moz-placeholder {
  color: transparent;
}
.admin-layout-container form .form-control:focus::-webkit-input-placeholder,
.admin-layout-container form .product_select:focus::-webkit-input-placeholder {
  color: transparent;
}

.admin-layout-container form .product_select .list {
  width: 100%;
}

.admin-layout-container form .input-group {
  padding-bottom: 40px;
}
.admin-layout-container form .input-group .custom-file {
  width: 100%;
  height: auto;
}
.admin-layout-container form .input-group .custom-file .custom-file-label {
  width: 168px;
  border-radius: 5px;
  cursor: pointer;
  font:
    700 14px/40px 'Roboto',
    sans-serif;
  border: 1px solid #99a2a8;
  text-align: center;
  transition: all 300ms linear 0s;
  color: #5f6771;
}
.admin-layout-container form .input-group .custom-file .custom-file-label i {
  font-size: 20px;
  padding-right: 10px;
}
.admin-layout-container form .input-group .custom-file .custom-file-label:hover,
.admin-layout-container form .input-group .custom-file .custom-file-label:focus {
  background: #daa520;
  border-color: #daa520;
  color: #fff;
}
.admin-layout-container form .input-group .custom-file input {
  display: none;
}
.admin-layout-container form .file_added {
  text-align: left;
  padding-left: 210px;
}
.admin-layout-container form .file_added li {
  font:
    400 15px/28px 'Roboto',
    sans-serif;
  color: #5f6771;
}
.admin-layout-container form .file_added li a {
  color: #daa520;
  font-weight: 500;
  display: inline-block;
  position: relative;
  padding-left: 15px;
}
.admin-layout-container form .file_added li a i {
  font-size: 22px;
  padding-right: 8px;
  position: absolute;
  left: 0;
  transform: rotate(20deg);
}
.multi_step_form #msform #progressbar {
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
}
.multi_step_form #msform #progressbar li {
  list-style-type: none;
  color: #99a2a8;
  font-size: 9px;
  width: calc(100% / 4);
  float: left;
  position: relative;
  font:
    500 13px/1 'Roboto',
    sans-serif;
}
.multi_step_form #msform #progressbar li:nth-child(2):before {
  content: '\f31a' !important;
}
.multi_step_form #msform #progressbar li:nth-child(3):before {
  content: '\f12a';
}
.multi_step_form #msform #progressbar li:nth-child(4):before {
  content: '\f3f4';
}
.multi_step_form #msform #progressbar li:before {
  content: '\f345';
  font: normal normal normal 30px/50px Ionicons;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  background: #f5f5f5;
  border-radius: 50%;
  margin: 0 auto 10px auto;
}
.multi_step_form #msform #progressbar li:after {
  content: '';
  width: 100%;
  height: 10px;
  background: #f5f5f5;
  position: absolute;
  left: -50%;
  top: 21px;
  z-index: -1;
}
.multi_step_form #msform #progressbar li:last-child:after {
  width: 150%;
}
.multi_step_form #msform #progressbar li.active ~ .multi_step_form #msform #progressbar li.active {
  color: #daa520;
}
.multi_step_form #msform #progressbar li.active:before,
.multi_step_form #msform #progressbar li.active:after {
  background: #daa520;
  color: white;
}
.multi_step_form #msform .action-button {
  background: #daa520;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 130px;
  font:
    700 14px/40px 'Roboto',
    sans-serif;
  border: 1px solid #daa520;
  margin: 0 5px;
  text-transform: uppercase;
  display: inline-block;
}
.multi_step_form #msform .action-button:hover,
.multi_step_form #msform .action-button:focus {
  background: #405867;
  border-color: #405867;
}
.multi_step_form #msform .previous_button {
  background: transparent;
  color: #99a2a8;
  border-color: #99a2a8;
}
.multi_step_form #msform .previous_button:hover,
.multi_step_form #msform .previous_button:focus {
  background: #c79006;
  border-color: #c79006;
  color: #fff;
}

.btn-circle-icon {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 8px 0 !important;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.admin-layout-container .form-label-group {
  position: relative;
  margin-bottom: 1rem;
}
