/* COLORS *
-----------------------------------------------------------------------*/
/* Grey dark */
/* Grey light */
/* Blue */
/* Green */
/* Pink */
/* Red */
/* Orange */
/* Black and White */
/* GLOBAL -------------------------------------------------*/
/* SCREENS --------------------------------------------------*/
/* Footer -------------------------------------*/
footer {
  width: 100%;
  margin: 0;
  background: #2a2a2a;
  color: #ffffff;
}
/* footer .container {
  display: flex;
  justify-content: space-between;
} */

footer .form-control {
  text-align: center;
  background-color: #EEEEEE;
  height: 38px;
  font-size: 14px;
  font-style: italic;
  color: #4c4c4c;
  border: none;
  border-radius: 0px;
  margin: 0;
}

footer .btn {
  margin: 0;
}

@media (min-width: 768px) {
  .text-md-left {
      text-align: left !important;
  }
}
@media only screen and (max-width: 768px) {
  footer .container {
    flex-direction: column;
  }
}
footer .item {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  footer .item {
    margin: 0 0 16px;
  }
  footer .item:nth-last-child(1) {
    margin: 0;
  }
}
footer .item h3 {
  color: #ffffff;
  margin: 0 0 10px;
  width: 100%;
}
footer .item a,
footer .item p {
  color: #ffffff;
  font-weight: 100;
  text-decoration: none;
  margin: 0 0 8px 0;
}
footer .item a,
footer .item a:visited {
  display: inline-block;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  font-weight: 100;
  margin: 0;
  height: 20px;
  transition: opacity 0.2s ease-in-out;
}
footer .item a:hover,
footer .item a:visited:hover {
  color: #ffffff;
  text-decoration: none;
}
footer .item a:hover:after,
footer .item a:visited:hover:after {
  display: block;
  content: '';
  border-bottom: 2px solid #ffffff;
}
footer .item .fa {
  margin-left: 10px;
  font-weight: 600;
}
footer .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
