:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}
.home-layout-container {
  top: 10px;
}

.home-layout-container .form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.home-layout-container .form-label-group > input,
.home-layout-container .form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: 55px;
  border-radius: 0.3rem;
}

.home-layout-container .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #5f6771;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}
.home-layout-container .form-label-group > input {
  max-width: 100%;
}

.home-layout-container .form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.home-layout-container .form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.home-layout-container .form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.home-layout-container .form-label-group input::-moz-placeholder {
  color: transparent;
}

.home-layout-container .form-label-group input::placeholder {
  color: transparent;
}

.home-layout-container .form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.home-layout-container .form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
  height: 20px;
}

/* Fallback for Edge
  -------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .home-layout-container .form-label-group > label {
    display: none;
  }
  .home-layout-container .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
  -------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .home-layout-container .form-label-group > label {
    display: none;
  }
  .home-layout-container .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
