button {
  font-size: 1rem;
  padding: 10px 20px 12px;
  /* ICONS */
}

button.btn {
  padding: 6px 20px;
}
button.btn-dashboard-subscribe{
  /* background-color: #DAA520; */
  color: #DAA520;
  border: 1px solid #DAA520;
}
button.btn-dashboard-subscribe:hover,
button.btn-dashboard-subscribe:hover:active,
button.btn-dashboard-subscribe:active,
button.btn-dashboard-subscribe:focus,
button.btn-dashboard-subscribe:focus:active {
  cursor: pointer;
  background-color: #DAA520;
  border: 1px solid #DAA520;
}
@media only screen and (max-width: 540px) {
  button.btn-dashboard-subscribe {
    display: block;
  }
}
button.btn-primary {
  background-color: #ec5e27;
  color: #ffffff;
  border: 0;
}
button.btn-primary:hover,
button.btn-primary:hover:active,
button.btn-primary:active,
button.btn-primary:focus,
button.btn-primary:focus:active {
  cursor: pointer;
}
@media only screen and (max-width: 540px) {
  button.btn-primary {
    display: block;
  }
}
button.subscribe{
  color: #ffffff;
  border: 0;
  background-color: #DAA520;
}
button.subscribe:hover,
button.subscribe:hover:active,
button.subscribe:active,
button.subscribe:focus,
button.subscribe:focus:active {
  cursor: pointer;
  background-color: #DAA520;
}
@media only screen and (max-width: 540px) {
  button.subscribe {
    display: block;
  }
}
button.btn-default {
  background: #ffffff;
  border: 1px solid #4a4a4a;
  color: #4a4a4a;
}
button.btn-default:hover,
button.btn-default:hover:active,
button.btn-default:active,
button.btn-default:focus,
button.btn-default:focus:active {
  background-color: #4a4a4a;
  color: #ffffff;
  border-color: none;
  cursor: pointer;
  transition: ease-in-out all 0.3s;
}
button.right {
  float: right;
  margin: 16px 0 16px 16px;
}
@media only screen and (max-width: 540px) {
  button.right {
    float: left;
    margin: 20px 20px 20px 0;
  }
}
button.icon-left {
  padding: 12px 20px 16px 45px;
}
button.add-link {
  position: relative;
}
button.add-link:after {
  font-family: FontAwesome;
  content: '\f055';
  font-size: 1.1em;
  font-weight: normal;
  position: absolute;
  right: 20px;
  top: 16px;
}
button.angle-right {
  position: relative;
}
button.angle-right:before {
  font-family: FontAwesome;
  content: '\f105';
  font-size: 1.1em;
  font-weight: normal;
  position: absolute;
  right: 10px;
}
button.download {
  position: relative;
}
button.download:before {
  font-family: FontAwesome;
  content: '\f019';
  font-size: 1.1em;
  font-weight: normal;
  position: absolute;
  right: 20px;
}
