/* -------------------------------- 
  
  Main Components 
  
  -------------------------------- */
.cd-header {
  position: relative;
  height: 150px;
  background-color: #331d35;
}
.cd-header h1 {
  color: #ffffff;
  line-height: 150px;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 300;
}
@media only screen and (min-width: 1170px) {
  .cd-header {
    height: 180px;
  }
  .cd-header h1 {
    line-height: 180px;
  }
}

.cd-main-content {
  position: relative;
  min-height: 100vh;
}
.cd-main-content:after {
  content: '';
  display: table;
  clear: both;
}
.cd-main-content.is-fixed .cd-tab-filter-wrapper {
  position: fixed;
  top: 91px;
  left: 0;
  width: 100%;
}
.cd-main-content.is-fixed .cd-gallery {
  padding-top: 76px;
}
.cd-main-content.is-fixed .cd-filter {
  position: fixed;
  height: 100vh;
  overflow: hidden;
  top: 91px;
}
.cd-main-content.is-fixed .cd-filter form {
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.cd-main-content.is-fixed .cd-filter-trigger {
  position: fixed;
  top: 91px;
}
.cd-main-content.is-fixed .cd-filter-trigger2 {
  position: fixed;
  top: 91px;
}
@media only screen and (min-width: 768px) {
  .cd-main-content.is-fixed .cd-gallery {
    padding-top: 90px;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-main-content.is-fixed .cd-gallery {
    padding-top: 100px;
  }
}

/* -------------------------------- 
  
  xtab-filter 
  
  -------------------------------- */
.cd-tab-filter-wrapper {
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  z-index: 1;
}
.cd-tab-filter-wrapper:after {
  content: '';
  display: table;
  clear: both;
}

.cd-tab-filter {
  /* tabbed navigation style on mobile - dropdown */
  position: relative;
  height: 50px;
  width: 140px;
  margin: 0 auto;
  z-index: 1;
}
.cd-tab-filter::after {
  /* small arrow icon */
  content: '';
  position: absolute;
  right: 14px;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
  width: 16px;
  height: 16px;
  /* background: url("../img/cd-icon-arrow.svg") no-repeat center center; */
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}
.cd-tab-filter ul {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  box-shadow: inset 0 -2px 0 #41307c;
}
.cd-tab-filter li {
  display: none;
}
.cd-tab-filter li:first-child {
  /* this way the placehodler is alway visible */
  display: block;
}
.cd-tab-filter a {
  display: block;
  /* set same size of the .cd-tab-filter */
  height: 50px;
  width: 140px;
  line-height: 50px;
  padding-left: 14px;
  text-decoration: none;
}
.cd-tab-filter a.selected {
  background: #41307c;
  color: #ffffff;
}
.cd-tab-filter.is-open::after {
  /* small arrow rotation */
  -webkit-transform: translateY(-50%) rotate(-180deg);
  -moz-transform: translateY(-50%) rotate(-180deg);
  -ms-transform: translateY(-50%) rotate(-180deg);
  -o-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}
.cd-tab-filter.is-open ul {
  box-shadow:
    inset 0 -2px 0 #41307c,
    0 2px 10px rgba(0, 0, 0, 0.2);
}
.cd-tab-filter.is-open ul li {
  display: block;
}
.cd-tab-filter.is-open .placeholder a {
  /* reduces the opacity of the placeholder on mobile when the menu is open */
  opacity: 0.4;
}
@media only screen and (min-width: 768px) {
  .cd-tab-filter {
    /* tabbed navigation style on medium devices */
    width: auto;
    cursor: auto;
  }
  .cd-tab-filter::after {
    /* hide the arrow */
    display: none;
  }
  .cd-tab-filter ul {
    background: transparent;
    position: static;
    box-shadow: none;
    text-align: center;
  }
  .cd-tab-filter li {
    display: inline-block;
  }
  .cd-tab-filter li.placeholder {
    display: none !important;
  }
  .cd-tab-filter a {
    display: inline-block;
    padding: 0 1em;
    width: auto;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 1rem;
  }
  .no-touch .cd-tab-filter a:hover {
    color: #000000;
  }
  .cd-tab-filter a.selected {
    background: transparent;
    color: #000;
    /* create border bottom using box-shadow property */
    box-shadow: inset 0 -2px 0 #000;
  }
  .cd-tab-filter.is-open ul li {
    display: inline-block;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-tab-filter {
    /* tabbed navigation on big devices */
    width: 100%;
    float: right;
    margin: 0;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    transition: width 0.3s;
  }
  .cd-tab-filter.filter-is-visible {
    /* reduce width when filter is visible */
    width: 80%;
  }
}

/* -------------------------------- 
  
  xgallery 
  
  -------------------------------- */
.cd-gallery {
  padding: 26px 5%;
  width: 100%;
}

.cd-gallery .cd-fail-message {
  display: none;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .cd-gallery {
    padding: 40px 3%;
  }
  .cd-gallery ul {
    text-align: justify;
  }
  .cd-gallery ul:after {
    content: '';
    display: table;
    clear: both;
  }
  .cd-gallery li {
    width: 48%;
    margin-bottom: 2em;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-gallery {
    padding: 50px 2%;
    float: right;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    transition: width 0.3s;
  }
  .cd-gallery li {
    width: 23%;
    padding-left: 4px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .cd-gallery.filter-is-visible {
    /* reduce width when filter is visible */
    width: 80%;
  }
}

/* -------------------------------- 
  
  xfilter 
  
  -------------------------------- */
.cd-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  height: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 20px transparent;
  z-index: 2;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition:
    -webkit-transform 0.3s,
    box-shadow 0.3s;
  -moz-transition:
    -moz-transform 0.3s,
    box-shadow 0.3s;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}
.cd-filter::before {
  /* top colored bar */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background-color: #c79006;
  z-index: 2;
}
.cd-filter form {
  padding: 70px 20px;
}
.cd-filter .cd-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  width: 60px;
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
  background: #daa520;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
  z-index: 3;
}
.no-touch .cd-filter .cd-close:hover {
  background: #c79006;
  text-decoration: none;
}
.cd-filter.filter-is-visible {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
}
.cd-filter.filter-is-visible .cd-close {
  opacity: 1;
}
.cd-filter form {
  overflow-y: scroll;
  height: calc(100vh - 300px);
}
@media only screen and (min-width: 1170px) {
  .cd-filter {
    width: 20%;
  }
  .cd-filter form {
    padding: 70px 10%;
  }
}

.cd-filter-trigger {
  position: absolute;
  top: 0;

  height: 50px;
  line-height: 50px;
  width: auto;
  left: 2%;
  text-indent: 0;
  color: #daa520;
  font-size: 1rem;
  font-weight: 600;

  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  /* image replacement */
  /*overflow: hidden;
    text-indent: 100%;
    color: transparent;
    white-space: nowrap;*/

  z-index: 3;
}
.cd-filter-trigger2 {
  position: absolute;
  top: 0;

  height: 50px;
  line-height: 50px;
  width: auto;
  right: 2%;
  text-indent: 0;
  color: #daa520;
  font-size: 1rem;
  font-weight: 600;

  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  /* image replacement */
  /*overflow: hidden;
    text-indent: 100%;
    color: transparent;
    white-space: nowrap;*/

  z-index: 3;
}

.cd-filter-trigger.filter-is-visible {
  pointer-events: none;
}
.cd-filter-trigger2.filter-is-visible {
  pointer-events: none;
}
@media only screen and (min-width: 1170px) {
  .cd-filter-trigger {
    width: auto;
    left: 2%;
    text-indent: 0;
    color: #daa520;
    font-size: 1rem;
    font-weight: 400;

    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
  }
  .cd-filter-trigger2 {
    width: auto;
    right: 2%;
    text-indent: 0;
    color: #daa520;
    font-size: 1rem;
    font-weight: 400;

    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
  }
  .no-touch .cd-filter-trigger:hover {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
  }
  .no-touch .cd-filter-trigger2:hover {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
  }
  .cd-filter-trigger.filter-is-visible,
  .cd-filter-trigger.filter-is-visible:hover {
    color: #ffffff;
  }
  .cd-filter-trigger2.filter-is-visible,
  .cd-filter-trigger2.filter-is-visible:hover {
    color: #ffffff;
  }
}

/* -------------------------------- 
  
  xcustom form elements 
  
  -------------------------------- */
.cd-filter-block {
  margin-bottom: 0px;
}
.cd-filter-block h4 {
  /* filter block title */
  position: relative;
  margin-bottom: 0.2em;
  padding: 10px 0 10px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.no-touch .cd-filter-block h4:hover {
  color: rgba(0, 0, 0, 0.7);
}
.cd-filter-block h4::before {
  /* arrow */
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  background: url('/public/img/cd-icon-arrow.svg') no-repeat center center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.cd-filter-block h4.closed::before {
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.cd-filter-block input,
.cd-filter-block select,
.cd-filter-block .radio-label::before,
.cd-filter-block .checkbox-label::before {
  /* shared style for input elements */
  font-family: 'Open Sans', sans-serif;
  border-radius: 0;
  background-color: #ffffff;
  border: 2px solid #e6e6e6;
}
.cd-filter-block input[type='search'],
.cd-filter-block input[type='text'],
.cd-filter-block select {
  width: 100%;
  padding: 0.8em !important;
  height: 40px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-shadow: none;
}
.cd-filter-block input[type='search']:focus,
.cd-filter-block input[type='text']:focus,
.cd-filter-block select:focus {
  outline: none;
  background-color: #ffffff;
  border-color: #daa520;
}
.cd-filter-block input[type='search'] {
  /* custom style for the search element */
  border-color: transparent;
  background-color: #e6e6e6;
  /* prevent jump - ios devices */
  font-size: 1rem !important;
}
.cd-filter-block input[type='search']::-webkit-search-cancel-button {
  display: none;
}
.cd-filter-block .cd-select {
  /* select element wrapper */
  position: relative;
}
.cd-filter-block .cd-select::after {
  /* switcher arrow for select element */
  content: '';
  position: absolute;
  z-index: 1;
  right: 14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  width: 16px;
  height: 16px;
  /* background: url("../img/cd-icon-arrow.svg") no-repeat center center; */
  pointer-events: none;
}
.cd-filter-block select {
  cursor: pointer;
  font-size: 1rem;
}
.cd-filter-block select::-ms-expand {
  display: none;
}

.cd-filter-block .radio-label::before,
.cd-filter-block .radio-label::after {
  border-radius: 50%;
}
.cd-filter-block .radio-label::after {
  /* check mark style for radio buttons */
  width: 6px;
  height: 6px;
  background-color: #ffffff;
  left: 5px;
}
.cd-filter-block input[type='radio']:checked + label::before,
.cd-filter-block input[type='checkbox']:checked + label::before {
  border-color: #daa520;
  background-color: #daa520;
}

@-moz-document url-prefix() {
  /* hide custom arrow on Firefox - select element */
  .cd-filter-block .cd-select::after {
    display: none;
  }
}
.cd-gallery-content h3 {
  color: #212529;
  font-size: 14px;
  padding: 3px;
}
.cd-gallery-content p {
  color: #212529;
  display: inline-block;
  font-size: 12px;
  padding: 3px;
}

.image-container {
  margin: auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 100%;
}

.image-box {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  padding: 5px;
  max-width: 100%;

  transition: 0.5s;

  margin-bottom: 1.6em;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border: solid 2px #fff;
  background-color: #fff;
  cursor: pointer;
  line-height: 0;
}
.image-box:hover {
  box-shadow: 0 1px 4px #daa522;
  border: solid 2px #daa522;
  background-color: #fefefe;
}

.image-box img {
  height: 150px;
  width: 100%;
  margin: auto;
  display: block;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
  height: calc(100% - 10vh);
  max-height: 150px;
}

@media screen and (max-width: 770px) {
.cd-filters-sort-by {
  display: none;
}
.image-grid {
  padding: 0px;
}
}

/* filter view */

.search-filter .note-warning {background-color: #ccc; padding:20px;line-height: 30px;}
.search-filter .btn-closing {
  border-radius: 50%;
  padding: 0.5em;
  width: 30px;
  height: 30px;
  border: 2px solid white;
  color: white;
  position: relative;
}
.search-filter .btn-closing:hover {
  border: 2px solid white;
  background-color: #C79006;
  color: #ffffff;
}

.search-filter .btn-closing::before {
  content: " ";
  position: absolute;
  display: block;
  background-color: white;
  width: 2px;
  left: 12px;
  top: 5px;
  bottom: 5px;
  transform: rotate(45deg);
}
.search-filter .btn-closing::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: white;
  height: 2px;
  top:12px;
  left: 5px;
  right: 5px;
  transform: rotate(45deg);
}
.search-filter .cd-filter-trigger:hover{text-decoration: none;}